import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";

const Icon = (props) => {
  const [flip, set] = useState(false);
  const pathRef = useRef();
  const [totalLength, setTotalLength] = useState(null);
  const animatedStyle = useSpring({
    strokeDasharray: totalLength,
    from: {
      strokeDashoffset: totalLength,
    },
    strokeDashoffset: 0,
    reset: true,
    reverse: flip,
    config: { mass: 1, tension: 310, friction: 180 },
    onRest: () => set(!flip),
  });

  useEffect(() => {
    setTotalLength(pathRef.current.getTotalLength());
  }, []);

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-30 -20 193.04 217.17"
    >
      {totalLength ? (
        <animated.path
          style={animatedStyle}
          stroke="#00b877" // defines the colour of the "line"
          strokeWidth="4"
          fill="none"
          ref={pathRef}
          d="M127.73,75.73c-10.9-29.4-31.3-51.7-54.3-72.2-5.4-4.9-8.9-4.6-14.3.3-16,14-30.4,29.4-41.9,47.5-10.9,17.2-18.7,35.5-17,56.5,2,25.5,17,42.3,37.5,55.2,3.2,2,6.6.9,8.9-2.1s2.3-6.6-.4-9.4a34.3,34.3,0,0,0-4.7-3.6c-25.7-17.7-32.9-42.5-20.7-71.1,5.7-13.6,13.9-25.6,23.6-36.6,7-8,14.6-15.6,22.2-23.4,18.7,17.2,34.7,35,45.2,57.3,12,25.5,8.6,47.8-9.9,65.5a88.93,88.93,0,0,1-27.4,18.3,3.1,3.1,0,0,0-1.3.6v-32.7c10.6-2.6,20.2-11.9,20-23.9-.1-7.4,0-13.7,0-21.2V72c0-2.9-.1-8.3-6.4-8.3h0a6.38,6.38,0,0,0-4.7,1.7c-1.7,1.9-1.6,4.3-1.6,6.4,0,0,0,24,.1,28,.1,4.4-2.7,8.1-7.1,10.9-.1-14.1.1-24.3-.1-37.8,0-2.9-.4-6-2.4-8-1.3-1.1-3.3-1.4-5.4-1.4-6,.3-5.7,9.3-5.7,9.3v37.9c-4.4-2.9-7.3-6.4-7.2-10.9.1-4,.1-28,.1-28,0-2.1.1-4.7-1.6-6.4a6.38,6.38,0,0,0-4.7-1.7h0c-6.4,0-6.4,5.5-6.4,8.3v8.6c0,7.3.1,13.6,0,21.2-.3,12,9.3,21.3,19.9,23.9v44.6a6.46,6.46,0,0,0,3.9,6c2,1.3,4.7,1.1,8-.6,11.1-5.7,22.4-11.4,32.6-18.7C131.33,136.53,139.63,107.93,127.73,75.73Z"
        ></animated.path>
      ) : (
        <path
          stroke="none" // defines the colour of the "line"
          fill="none"
          ref={pathRef}
          d="M127.73,75.73c-10.9-29.4-31.3-51.7-54.3-72.2-5.4-4.9-8.9-4.6-14.3.3-16,14-30.4,29.4-41.9,47.5-10.9,17.2-18.7,35.5-17,56.5,2,25.5,17,42.3,37.5,55.2,3.2,2,6.6.9,8.9-2.1s2.3-6.6-.4-9.4a34.3,34.3,0,0,0-4.7-3.6c-25.7-17.7-32.9-42.5-20.7-71.1,5.7-13.6,13.9-25.6,23.6-36.6,7-8,14.6-15.6,22.2-23.4,18.7,17.2,34.7,35,45.2,57.3,12,25.5,8.6,47.8-9.9,65.5a88.93,88.93,0,0,1-27.4,18.3,3.1,3.1,0,0,0-1.3.6v-32.7c10.6-2.6,20.2-11.9,20-23.9-.1-7.4,0-13.7,0-21.2V72c0-2.9-.1-8.3-6.4-8.3h0a6.38,6.38,0,0,0-4.7,1.7c-1.7,1.9-1.6,4.3-1.6,6.4,0,0,0,24,.1,28,.1,4.4-2.7,8.1-7.1,10.9-.1-14.1.1-24.3-.1-37.8,0-2.9-.4-6-2.4-8-1.3-1.1-3.3-1.4-5.4-1.4-6,.3-5.7,9.3-5.7,9.3v37.9c-4.4-2.9-7.3-6.4-7.2-10.9.1-4,.1-28,.1-28,0-2.1.1-4.7-1.6-6.4a6.38,6.38,0,0,0-4.7-1.7h0c-6.4,0-6.4,5.5-6.4,8.3v8.6c0,7.3.1,13.6,0,21.2-.3,12,9.3,21.3,19.9,23.9v44.6a6.46,6.46,0,0,0,3.9,6c2,1.3,4.7,1.1,8-.6,11.1-5.7,22.4-11.4,32.6-18.7C131.33,136.53,139.63,107.93,127.73,75.73Z"
        ></path>
      )}
    </svg>
  );
};

Icon.propTypes = {
  toggle: PropTypes.bool,
};

const Loader = (props) => {
  return (
    <div className="w-48 h-48 p-4">
      <Icon />
    </div>
  );
};

Loader.propTypes = {};

export default Loader;
/* <path
          stroke="none" // defines the colour of the "line"
          fill="none"
          ref={pathRef}
          d="M0 121.57l50.72-30.42c2.83 5.21 225.78 389.25 228.68 394q50.51 83.57 115.13 83.56a118.35 118.35 0 0056.39-14q26.37-14 40.17-37.52a97.18 97.18 0 0013.78-49.89q0-30-20.27-58.83-28-39.75-102.25-95.74-74.62-56.39-92.89-81.54Q257.81 189 257.81 140q0-39 18.67-71T329 18.46A151.37 151.37 0 01402.64 0a156.92 156.92 0 0178.9 20.89q32.83 18.69 69.05 65.4c2.86 3.7 249.78 323.29 252.67 327.25l-48.68 36.92c-3.22-4.28-250.41-324.1-253.49-328Q473.48 88 453.15 75.86a99 99 0 00-51.72-14.2q-36.12 0-59 21.92t-22.91 53.94a91.75 91.75 0 008.11 37.73q8.12 18.27 29.62 39.76 11.76 11.37 77.08 59.64 77.48 57.19 106.29 101.82t28.8 89.65q0 64.92-49.3 112.78t-119.91 47.87q-54.37 0-98.59-29-39.78-26.1-74-83.9C225.09 509.58 2.49 126.13 0 121.57z"
        ></path> */
