import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  error: false,
  data: undefined,
};

const reviewListSlice = createGenericSlice({
  name: 'reviewList',
  initialState,
  reducers: {
    success(state) {
      state.success = true;
    },
  },
});

const { actions, reducer } = reviewListSlice;

const reviewListState = (state) => state.reviewList;

export const reviewListSelector = createDraftSafeSelector(reviewListState, (state) => ({
  error: state.error,
  loading: state.loading,
  reviewListPayload: state.data ? state.data.data : [],
  total: state.data ? state.data.total : 0,
  totalPages: state.data ? state.data.totalPages : 0
}));

export const { start, finishError, finishSuccess, success, error, reviewListPayload } = actions;

export default reducer;
