import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  success: false,
  error: false,
};

const mailSlice = createGenericSlice({
  name: 'mail',
  initialState,
  reducers: {
    success(state) {
      state.success = true;
    },
  },
});

const { actions, reducer } = mailSlice;

const mailState = (state) => state.mail;

export const mailSelector = createDraftSafeSelector(mailState, (state) => ({
  success: state.success,
  error: state.error,
}));

export const { start, finishError, finishSuccess, success, error } = actions;

export default reducer;
