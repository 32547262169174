import React from 'react';
import PropTypes from 'prop-types';

const NestedTextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [key, name] = field.name.split('.');

  const displayError = touched[key] && errors[key] && touched[key][name] && errors[key][name];

  return (
    <div className="flex flex-col items-end w-6/12">
      <input
        type="text"
        className={`w-full bg-transparent border-b my-1 p-1 ${displayError ? 'border border-red-500 placeholder-red-500' : 'border-darkGreen placeholder-darkGreen'}`}
        {...field}
        {...props}
      />
      {displayError && <p className="text-sm text-red-500">{errors[key][name]}</p>}
    </div>
  );
};

NestedTextField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default NestedTextField;
