import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import TextAreaField from './../../../shared/components/textarea-field/TextareaField';
import RoundLoader from '../../../shared/components/round-loader/RoundLoader';
import ReviewRating from '../review-rating/ReviewRating';

const reviewFormValidationSchema = (t) =>
  Yup.object({
    text: Yup.string().required(t('text is required')),
    rating: Yup.number()
      .min(1, t('rating_is_required'))
      .required(t('rating_is_required'))
  });

const ReviewForm = ({ handleSubmit, user, loading }) => {

  const { t } = useTranslation();

  return (
    <div className='py-4 lg:py-4'>
      <Formik
        initialValues={{
          text: '',
          rating: 0,
        }}
        onSubmit={handleSubmit}
        validationSchema={reviewFormValidationSchema(t)}
        enableReinitialize={true}
      >
        {({ setFieldValue, isSubmitting, values, errors, touched, handleChange }) => (
          <Form>
            <div>
              <div>{user && user.first_name + ' ' + user.last_name}</div>
              <div>{user && user.email}</div>
            </div>

            <div className='pb-4 pt-4'>
              <Field name="rating" setFieldValue={setFieldValue} onChange={handleChange} component={ReviewRating} />
              {errors.rating && touched.rating ? <div className="text-red-500">{t('rating_is_required')}</div> : null}
              <Field
                name='text'
                placeholder={t('review')}
                height='h-80'
                component={TextAreaField}
              />
            </div>

            <button
              type='submit'
              className='border border-green py-4 px-6 flex items-center justify-center w-60 text-green hover:bg-green hover:text-white'
              disabled={loading}
            >
              {loading ? <RoundLoader /> : t('send')}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ReviewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ReviewForm;
