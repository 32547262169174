import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from '../../../shared/components/loader/Loader';
import PageContainer from '../../../shared/components/page-container/PageContainer';
import withUserPermission from '../../../shared/components/with-user-permission/withUserPermission';
import OrdersList from './../orders-list/OrdersList';

import { getOrdersForCustomer } from '../../../shared/api-path-generators';

import { start, finishError, finishSuccess, customerOrdersSelector } from '../../../store/slices/customerOrdersSlice';

const OrdersPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, error, customerOrders } = useSelector(customerOrdersSelector);

  useLayoutEffect(() => {
    dispatch(start(null, finishSuccess, finishError, getOrdersForCustomer(), 'GET'));
  }, [dispatch]);

  return (
    <PageContainer>
      {error && <div className="border border-red-500 p-4 text-red-500 mb-4">Hiba történt</div>}
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <div className="mx-auto w-6/12">
          <h1 className="text-2xl">{t('orders')}</h1>
          <div className="py-8">
            {customerOrders.length === 0 ? (
              <div className="text-center">Nem rendeltél még tőlünk.</div>
            ) : (
              <OrdersList orders={customerOrders} />
            )}
          </div>
        </div>
      )}
    </PageContainer>
  );
};

OrdersPage.propTypes = {};

export default withUserPermission(OrdersPage);

//  successAction, errorAction, url, type
