const apiUrl = process.env.REACT_APP_API || '';
const ordersApi = process.env.REACT_APP_ORDERS_API || '';

export const getSlider = () =>
  `${apiUrl}/wp-json/wp/v2/posts?categories=2&_embed&per_page=100`; //lang=${params.lang}

export const getPages = () => `${apiUrl}/wp-json/wp/v2/pages?per_page=100`;

export const getColors = () =>
  `${apiUrl}/wp-json/wp/v2/posts?categories=23&_embed&per_page=100`;

export const getWebshopCategories = () =>
  `${apiUrl}/wp-json/wc/v3/products/categories?_embed&per_page=100`;

export const getProductsByCategory = (categoryId) => {
  return `${apiUrl}/wp-json/wc/v3/products?category=${categoryId}&_embed&per_page=100&orderby=menu_order&order=asc`;
};

export const getAllProducts = (lang) =>
  `${apiUrl}/wp-json/wc/v3/products?_embed&per_page=100&lang=${lang}&orderby=menu_order&order=asc`;

export const getShippingZones = (lang) =>
  `${apiUrl}/wp-json/wc/v3/shipping/zones?lang=${lang}`;

export const getShippingMetods = (zoneId) =>
  `${apiUrl}/wp-json/wc/v3/shipping/zones/${zoneId}/methods?lang=hu`;

export const getProductById = (productId) =>
  `${apiUrl}/wp-json/wc/v3/products/${productId}?_embed&lang=hu`;

export const getProductVariations = (productId) =>
  `${apiUrl}/wp-json/wc/v3/products/${productId}/variations?per_page=100&lang=hu`;

export const postToCart = (cartKey) =>
  `${apiUrl}/wp-json/cocart/v2/cart/add-item${cartKey ? '?cart_key=' + cartKey : ''
  }`;

export const getCartPath = (cartKey) =>
  `${apiUrl}/wp-json/cocart/v2/cart${cartKey ? '?cart_key=' + cartKey : ''}`;

export const removeItemFromCart = (cartKey, itemKey) =>
  `${apiUrl}/wp-json/cocart/v2/cart/item/${itemKey}${cartKey ? '?cart_key=' + cartKey : ''
  }`;

export const clearCartPath = (cartKey) =>
  `${apiUrl}/wp-json/cocart/v2/cart/clear${cartKey ? '?cart_key=' + cartKey : ''
  }`;

export const postOrderPath = (orderId) =>
  `${ordersApi}/api/order${orderId ? `/${orderId}` : ''}`;

export const authenticateUser = (email, password) =>
  `${apiUrl ? apiUrl + '/?rest_route=' : '/api-route'
  }/simple-jwt-login/v1/auth&email=${email}&password=${password}`;

export const valideteToken = () =>
  `${apiUrl ? apiUrl + '/?rest_route=' : '/api-route'
  }/simple-jwt-login/v1/auth/validate`;

export const refreshToken = () =>
  `${apiUrl ? apiUrl + '/?rest_route=' : '/api-route'
  }/simple-jwt-login/v1/auth/refresh`;

export const revokeToken = () =>
  `${apiUrl ? apiUrl + '/?rest_route=' : '/api-route'
  }/simple-jwt-login/v1/auth/revoke`;

export const cocartLogoutPath = () => `${apiUrl}/wp-json/cocart/v2/logout`;

export const getCustomer = () => `${ordersApi}/api/customer`;

export const postMailPath = () => `${ordersApi}/api/mail`;

export const createCustomer = () => `${ordersApi}/api/customer`;

export const getOrdersForCustomer = () => `${ordersApi}/api/orders`;

export const getOrderById = (id) => `${ordersApi}/api/order/${id}`;

export const getGuestOrder = () => `${ordersApi}/api/guest/order`;

export const updateCustomer = () => `${ordersApi}/api/customer`;

export const getCountries = () => `${apiUrl}/wp-json/wc/v3/data/countries`;

export const getContinentData = (continent) =>
  `${apiUrl}/wp-json/wc/v3/data/continents/${continent}`;

export const startSimplePayPayment = () =>
  `${ordersApi}/api/pay`;

export const getCouponPath = (couponID) => `${apiUrl}/wp-json/wc/v3/coupons?code=${couponID}`;

export const postReviewPath = () => `${ordersApi}/api/reviews`;

export const getReviewPath = (page, pageSize) => `${ordersApi}/api/reviews?perPage=${pageSize}&page=${page}`;