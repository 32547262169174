import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  data: undefined,
  error: false,
};

const simplePaySlice = createGenericSlice({
  name: 'simplePay',
  initialState,
  reducers: {},
});

const { actions, reducer } = simplePaySlice;

const simplePayState = (state) => state.simplePay;

export const simplePaySelector = createDraftSafeSelector(
  simplePayState,
  (state) => ({
    simplePay: state.data,
    loading: state.loading,
    error: state.error,
  })
);

export const { start, finishError, finishSuccess } = actions;

export default reducer;
