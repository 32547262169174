import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { cartSelector } from './../../../store/slices/cartSlice';

import { numberFormatter } from './../../../shared/helpers';

import { isEmpty } from 'ramda'

import PageContainer from './../../../shared/components/page-container/PageContainer';
import CartList from './../cart-list/CartList';
import OrderSummary from './../order-summary/OrderSummary';

const Cart = (props) => {
  const { t } = useTranslation();
  const { cart } = useSelector(cartSelector);

  return (
    <PageContainer>
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:pt-12 px-6 lg:px-0">
        <div className="col-span-2 lg:pr-12">
          <h2 className="text-center lg:text-left text-2xl pb-4">{t('Your shopping cart')}</h2>
          {cart && !isEmpty(cart) ? (
            <>
              <div className="w-full">
                <div className="border-b border-darkGreen text-sm hidden lg:flex flex-row font-bold">
                  <div className="w-5/12 text-left">{t('product')}</div>
                  <div className="w-2/12 text-left">{t('packaging')}</div>
                  <div className="w-2/12 text-center">{t('color')}</div>
                  <div className="w-1/12 text-right">{t('quantity')}</div>
                  <div className="w-2/12 text-right">{t('price')}</div>
                </div>
                <div className="w-full lg:hidden border-b border-green"></div>
                <CartList items={cart.items} />
              </div>
              <div className="hidden lg:flex flex-row justify-between">
                <p className="py-4">
                  {t('total items')}: {cart.item_count}
                </p>
                <p className="py-4">
                  {t('total value without shipping')}: {numberFormatter(parseInt(cart.totals.subtotal, 10) + parseInt(cart.totals.subtotal_tax))} HUF
                </p>
              </div>
            </>
          ) : (
            <div className="text-center lg:text-left">Jelenleg üres a kosarad.</div>
          )}
        </div>
        {cart && !isEmpty(cart) && (
          <div className="bg-darkGreen p-6 mt-4">
            <OrderSummary cart={cart} checkout={false} />
          </div>
        )}
      </div>
    </PageContainer>
  );
};

Cart.propTypes = {};

export default Cart;
