import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReCaptchaV2 from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';

import { register, registerSuccess, registerError, userSelector, reset } from './../../../store/slices/userSlice';
import { createCustomer } from './../../api-path-generators';

import RegisterForm from './../register-form/RegisterForm';

const RegisterCustomer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const recaptchaRef = React.createRef();
  const { loading, error, user } = useSelector(userSelector);

  useEffect(() => {
    if (user) {
      dispatch(reset());
      history.goBack();
    }
  }, [dispatch, user]);

  const handleRegisterUser = async (values) => {
    const token = await recaptchaRef.current.executeAsync();

    const toPost = {
      ...values,
      username: values.email,
      'g-recaptcha-response': token,
    };

    dispatch(register(toPost, registerSuccess, registerError, createCustomer(), 'POST', 'Sikeres regisztráció.'));
  };
  return (
    <div className="w-full lg:w-6/12 px-4">
      <RegisterForm handleSubmit={handleRegisterUser} loading={loading} />
      <ReCaptchaV2 ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY} size="invisible" />
    </div>
  );
};

RegisterCustomer.propTypes = {};

export default RegisterCustomer;
