import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  data: [],
  error: false,
};

const productsSlice = createGenericSlice({
  name: 'products',
  initialState,
  reducers: {},
});

const { actions, reducer } = productsSlice;

const productsState = (state) => state.products;

export const productsSelector = createDraftSafeSelector(
  productsState,
  (state) => ({
    products: state.data,
    loading: state.loading,
    error: state.error,
  })
);

export const { start, finishError, finishSuccess } = actions;

export default reducer;
