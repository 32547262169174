import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDebounce } from 'react-use';

import RoundLoader from "./../../../../shared/components/round-loader/RoundLoader";

const CouponField = ({ handleAddCoupon, handleValidateCoupon, valid, validationLoading, loading }) => {
  const [val, setVal] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const { t } = useTranslation();

  useDebounce(
    () => {
      setDebouncedValue(val);
    },
    1000,
    [val]
  );

  useEffect(() => {
    handleValidateCoupon(debouncedValue)
  }, [debouncedValue])

  const handleInputChange = ({ currentTarget }) => {
    setVal(currentTarget.value)
  }

  const handleAdd = () => {
    handleAddCoupon(debouncedValue)
    setVal('')
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center pt-3 space-x-2">
        <div className="w-full relative">
          <input type="text" value={val} onChange={handleInputChange} className="border border-green w-full p-1"/>
          {validationLoading && <div className="absolute top-0 right-1 flex h-full items-center"> <RoundLoader /></div>}
        </div>
        <button
          type="submit"
          className="border border-green py-4 px-6 flex items-center justify-center h-5 w-24 lg:w-24 text-green hover:bg-green hover:text-white"
          disabled={loading || !valid}
          onClick={handleAdd}
        >
          {loading ? <RoundLoader /> : t("apply_coupon")}
        </button>
      </div>
    </>
  );
};

CouponField.propTypes = {};

export default CouponField;
