import { call, put, all } from 'redux-saga/effects';
import { httpRequest, authedHttpRequest, barionHttpRequest } from './api-calls';
import toast from 'react-hot-toast';

import { clone } from 'ramda';

export function* workerSaga(action) {
  const {
    payload,
    meta: { successAction, errorAction, url, type, toastMessage },
  } = action;

  const apiCall = httpRequest(url, type);

  try {
    const { data } = yield call(apiCall, payload);
    toastMessage && toast(toastMessage);
    yield all([put(successAction(data))]);
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}

export function* authedWorkerSaga(action) {
  const {
    payload,
    meta: { successAction, errorAction, url, type, toastMessage },
  } = action;

  const apiCall = authedHttpRequest(url, type);

  const postData = {
    data: clone(payload),
    token: localStorage.getItem('vilhemp-token'),
  };

  try {
    const { data } = yield call(apiCall, postData);
    toastMessage && toast(toastMessage);
    yield all([put(successAction(data))]);
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}