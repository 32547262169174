import React from 'react';
import PropTypes from 'prop-types';

const PageContainer = ({ children }) => {
  return <div className="h-full pt-16 pb-8 lg:pb-0 lg:pt-24 px-4">{children}</div>;
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;
