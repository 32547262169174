import { call, put, all, select } from 'redux-saga/effects';
import { httpRequest, authedHttpRequest } from './api-calls';
import toast from 'react-hot-toast';

import { userSelector } from './../slices/userSlice';

export function* cartWorkerSaga(action) {
  const {
    payload,
    meta: { successAction, errorAction, url, type, toastMessage },
  } = action;

  const { auth } = yield select(userSelector);
  const token = localStorage.getItem('vilhemp-token');
  // console.log('auth', auth, token)
  const apiCall = auth ? authedHttpRequest(url, type) : httpRequest(url, type);

  const postData = {
    token,
    data: payload
  }

  try {
    const toSend = auth ? postData : payload;
    const { data } = yield call(apiCall, toSend);

    const oldCartKey = localStorage.getItem('cart_key');

    if (!auth && !oldCartKey) {
      localStorage.setItem('cart_key', data.cart_key);
    }

    toastMessage && toast(toastMessage);
    yield all([put(successAction(data))]);
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}
