import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const createGenericSlice = ({ name = '', initialState, reducers }) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      start: {
        reducer: (state) => {
          state.error = false;
          state.loading = true;
        },
        prepare(payload, successAction, errorAction, url, type) {
          return { payload, meta: { successAction, errorAction, url, type } };
        },
      },
      finishSuccess(state, action) {
        state.data = action.payload;
        state.loading = false;
        state.success = true;
      },
      finishError(state, action) {
        state.error = true;
        state.loading = false;
        state.error=true;
      },
      ...reducers,
    },
  });
};

export default createGenericSlice;
