import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  cart: undefined,
  error: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    getCart: {
      reducer: (state) => {
        state.error = false;
        state.loading = true;
      },
      prepare(payload, successAction, errorAction, url, type) {
        return { payload, meta: { successAction, errorAction, url, type } };
      },
    },
    addToCart: {
      reducer: (state) => {
        state.error = false;
        state.loading = true;
      },
      prepare(payload, successAction, errorAction, url, type, toastMessage) {
        return { payload, meta: { successAction, errorAction, url, type, toastMessage } };
      },
    },
    removeFromCart: {
      reducer: (state) => {
        state.error = false;
        state.loading = true;
      },
      prepare(payload, successAction, errorAction, url, type, toastMessage) {
        return { payload, meta: { successAction, errorAction, url, type, toastMessage } };
      },
    },
    clearCart: {
      reducer: (state) => {
        state.error = false;
        state.loading = true;
      },
      prepare(payload, successAction, errorAction, url, type) {
        return { payload, meta: { successAction, errorAction, url, type } };
      },
    },
    addToCartSuccess(state, action) {
      state.cart = action.payload;
      state.loading = false;
    },
    finishSuccess(state, action) {
      state.cart = action.payload;
      state.loading = false;
    },
    finishError(state, action) {
      state.error = true;
      state.loading = false;
    },
    resetCart(state) {
      state.cart = undefined;
      state.error = false;
      state.loading = false;
    }
  },
});

const { actions, reducer } = cartSlice;

const cartState = (state) => state.cart;

export const cartSelector = createDraftSafeSelector(cartState, (state) => ({
  cart: state.cart,
  loading: state.loading,
  error: state.error,
}));

export const cartItemsNumberSelector = createDraftSafeSelector(cartState, (state) => ({
  itemsNumber: state.cart?.items ? state.cart.items.length : 0,
}));

export const cartKeySelector = createDraftSafeSelector(cartState, (state) => ({
  cartKey: state.cart ? state.cart.cart_key : '',
}));

export const { getCart, addToCart, clearCart, removeFromCart, finishError, finishSuccess, addToCartSuccess, resetCart } = actions;

export default reducer;
