import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { pageSelector } from './../../../store/slices/pageSlice';
import PageContainer from './../../../shared/components/page-container/PageContainer';

export const createMarkup = (data) => ({ __html: data });

const StaticPage = (props) => {
  const { pages } = useSelector(pageSelector);
  const routeParams = useParams();

  const { slug } = routeParams;

  const page = useMemo(() => {
    return pages.find((page) => page.slug === slug);
  }, [slug, pages]);

  return (
    <PageContainer>
      <div className="mx-auto lg:w-8/12">
        <h1 className="text-2xl pb-12">{page.title.rendered}</h1>
        <div dangerouslySetInnerHTML={createMarkup(page.content.rendered)}></div>
      </div>
    </PageContainer>
  );
};

StaticPage.propTypes = {};

export default StaticPage;
