import React from 'react';
import PropTypes from 'prop-types';

const Chevron = (props) => {
  const chevrons = {
    down: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    ),
    left: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    ),
    right: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    ),
    up: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
      </svg>
    ),
  };

  return <>{chevrons[props.direction]}</>;
};

Chevron.propTypes = {
  direction: PropTypes.string.isRequired,
};

export default Chevron;
