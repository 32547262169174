import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import CouponField from "./../coupon-field/CouponField";

import {
  start as startOrder,
  finishError as finishErrorOrder,
  finishSuccess as finishSuccessOrder,
  orderSelector,
} from "./../../../../store/slices/orderSlice";

import {
  getCoupon,
  finishSuccess,
  finishError,
  couponSelector,
  reset,
} from "./../../../../store/slices/couponSlice";

import {
  getCouponPath,
  postOrderPath,
} from "./../../../../shared/api-path-generators";

import {
  isExisting,
  isNotExpired,
  overallUsageLimit,
  userUsageLimit,
  userAllowedToUse,
} from "./coupon-validators";

const Coupon = () => {
  const dispatch = useDispatch();
  const [validationLoading, setValidationLoading] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [couponValidationError, setCouponValidationError] = useState(false);

  const { order, loading, error } = useSelector(orderSelector);
  const { coupon, loading: couponLoading } = useSelector(couponSelector);

  const couponLines = useMemo(() => {
    return (
      order?.coupon_lines.map((line) => (
        <div
          key={line.code}
          className="uppercase p-1 border border-green bg-white"
        >
          {line.code}
        </div>
      )) || []
    );
  }, [order]);

  useEffect(() => {
    if (Array.isArray(coupon) && coupon.length == 1 && order) {
      setValidationLoading(true);
      const couponData = coupon[0];

      if (
        isExisting(coupon) &&
        isNotExpired(couponData.date_expires) &&
        overallUsageLimit(couponData.usage_count, couponData.usage_limit) &&
        userUsageLimit(
          couponData.usage_count,
          couponData.usage_limit_per_user
        ) &&
        userAllowedToUse(couponData.email_restrictions, order.billing.email)
      ) {
        setCouponValid(true);
      } else {
        setCouponValidationError(true);
      }
    }
    if (Array.isArray(coupon) && coupon.length == 0) {
      setCouponValidationError(true);
    }
    setValidationLoading(false);
  }, [coupon, order, setValidationLoading, setCouponValidationError]);

  const handleCoupon = (coupon) => {
    if (coupon) {
      dispatch(
        getCoupon(
          null,
          finishSuccess,
          finishError,
          getCouponPath(coupon),
          "GET"
        )
      );
    } else {
      setCouponValid(false);
      setCouponValidationError(false);
      setValidationLoading(false);
    }
  };

  const handleAddToOrder = (code) => {
    if (couponValid) {
      const postObject = {
        coupon_lines: [{ code }],
        id: order.id,
      };
      dispatch(
        startOrder(
          postObject,
          finishSuccessOrder,
          finishErrorOrder,
          postOrderPath(order.id),
          "PUT"
        )
      );
      setCouponValid(false);
      setCouponValidationError(false);
      dispatch(reset());
    }
  };

  const handleRemoveCoupons = () => {
    const postObject = {
      coupon_lines: [],
      id: order.id,
    };
    dispatch(
      startOrder(
        postObject,
        finishSuccessOrder,
        finishErrorOrder,
        postOrderPath(order.id),
        "PUT"
      )
    );
  }

  return (
    <>
      {order && (
        <div className="pt-4">
          <h2 className="text-lg text-white">Kedvezmény kuponok</h2>
          <CouponField
            handleAddCoupon={handleAddToOrder}
            handleValidateCoupon={handleCoupon}
            valid={couponValid}
            validationLoading={validationLoading || couponLoading}
            loading={loading}
          />
          {couponValidationError && (
            <div className="text-red-500 text-sm py-2">
              {!isExisting(coupon) && <div>Nincs ilyen kupon.</div>}
              {isExisting(coupon) && (
                <>
                  {!isNotExpired(coupon[0].date_expires) && (
                    <div>Lejárt kupon.</div>
                  )}
                  {!overallUsageLimit(
                    coupon[0].usage_count,
                    coupon[0].usage_limit
                  ) && <div>Többször nem használható.</div>}
                  {!userUsageLimit(
                    coupon[0].usage_count,
                    coupon[0].usage_limit_per_user
                  ) && <div>Többször nem használhatod.</div>}
                  {!userAllowedToUse(
                    coupon[0].email_restrictions,
                    order.billing.email
                  ) && <div>Nem használhatod.</div>}
                </>
              )}
            </div>
          )}
          {couponValid && <div className="text-sm py-2 text-white">Érvényes kupon!</div>}
          {order?.coupon_lines.length > 0 && (
            <>
              <div className="flex py-2">{couponLines}</div>
              <button className="text-sm hover:underline text-white" onClick={handleRemoveCoupons}>Kuponok eltávolítása</button>
            </>
          )}
        </div>
      )}
    </>
  );
};

Coupon.propTypes = {};

export default Coupon;
