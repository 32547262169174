import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

import RoundLoader from './../../../shared/components/round-loader/RoundLoader';
import PaymentMethodField from './../../../shared/components/payment-method-field/PaymentMethodField';

import { ReactComponent as SimpleLogo } from './../../../shared/images/simplepay_bankcard_logos_top_02.svg';

const PaymentMethod = ({ loading, values }) => {
  const { t } = useTranslation();

  const isLocalPickup = values.shipping_method === 'local_pickup';

  return (
    <>
      <div className="flex flex-col w-full pt-8">
        <Field
          type="radio"
          value="cod"
          component={PaymentMethodField}
          name="payment_method"
          header={t('Cash on delivery')}
          text={t('In person')}
          icon={() => <></>}
          disabled={!isLocalPickup}
          checked={values.payment_method === 'cod'}
        />
        <Field
          type="radio"
          value="bacs"
          component={PaymentMethodField}
          name="payment_method"
          header={t('SEPA Transfer')}
          text={t('Transfer in advance')}
          icon={() => <></>}
          checked={values.payment_method === 'bacs'}
        />
        <Field
          type="radio"
          value="simplepay-gateway"
          component={PaymentMethodField}
          name="payment_method"
          header={t('Credit or Debit Card')}
          text={t('with simple')}
          icon={() => (
            <div className="bg-white p-4 border">
              <a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank" rel="noreferrer">
                <SimpleLogo className="h-16" />
              </a>
            </div>
          )}
          checked={values.payment_method === 'simplepay-gateway'}
        />
        <div className="text-red-500 mb-4">
          <ErrorMessage name="payment_method" />
        </div>
      </div>
      <div className="pb-6">
        <button
          type="submit"
          className="bg-orange py-4 px-6 flex items-center justify-center w-full lg:w-60 text-white hover:bg-darkGreen hover:text-white"
          disabled={loading}
        >
          {loading ? <RoundLoader /> : t('next')}
        </button>
      </div>
    </>
  );
};

PaymentMethod.propTypes = {
  loading: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PaymentMethod;
