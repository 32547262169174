import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink, Prompt, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import { AppContext } from "./../../../components/app/AppContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import { ReactComponent as Logo } from "./vilhemp_logo.svg";
import CartIcon from "./../cart-icon/CartIcon";
import UserIcon from "./../user-icon/UserIcon";
import MenuIcon from "./../menu-icon/MenuIcon";
import XIcon from "./../x-icon/XIcon";

import CartHover from "../cart-hover/CartHover";
import HeaderLogin from "./../header-login/HeaderLogin";

import {
  cartItemsNumberSelector,
  cartSelector,
} from "./../../../store/slices/cartSlice";
import { userSelector } from "./../../../store/slices/userSlice";

import { generateCategoryUrl, generateCartUrl } from "./../../frontend-routes";

const generateSubmenus = (webshopMenus, lang) => {
  const subMenus = {};

  webshopMenus.forEach((item) => {
    subMenus[item.slug] = [
      <NavLink
        key={item.name}
        to={generateCategoryUrl(lang, item.slug)}
        aria-current={item.current ? "page" : undefined}
        className="text-base py-1 tracking-normal"
      >
        All
      </NavLink>,
    ];

    subMenus[item.slug] = subMenus[item.slug].concat(
      item.children.map((child) => (
        <NavLink
          key={child.name}
          to={generateCategoryUrl(lang, `${item.slug}/${child.slug}`)}
          aria-current={item.current ? "page" : undefined}
          className="text-base py-1 tracking-wide"
        >
          {child.name}
        </NavLink>
      ))
    );
  });

  return subMenus;
};

const HeaderContainer = styled.nav.attrs({
  className: "w-screen fixed flex-col bg-green z-10",
})``;

const Header = ({ webshopMenu, onLogout }) => {
  const [activeSubmenu, setActiveSubmenu] = useState("");
  const [open, setOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const { itemsNumber } = useSelector(cartItemsNumberSelector);
  const { auth } = useSelector(userSelector);
  const { cart } = useSelector(cartSelector);
  const { language } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const subMenus = generateSubmenus(webshopMenu, language);

  const handleHover = (submenu) => {
    setActiveSubmenu(submenu);
  };

  const handleLogout = () => {
    onLogout();
  };

  const handleOpen = () => setOpen((prev) => !prev);

  const handleMobileSubmenuOpen = (submenu) => {
    if (activeSubmenu === submenu) {
      setActiveSubmenu("");
    } else {
      setActiveSubmenu(submenu);
    }
  };

  const handleLoginOpen = () => setLoginOpen((prev) => !prev);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    history.push(`/${lang}`);
  };

  return (
    <HeaderContainer onMouseLeave={() => handleHover()}>
      <div className="w-screen flex justify-between">
        <button
          className="inline-flex lg:hidden items-center justify-center p-2 text-black hover:text-white hover:bg-gray-700"
          onClick={handleOpen}
        >
          <span className="sr-only">Open main menu</span>
          {open ? (
            <XIcon className="h-6 w-6" />
          ) : (
            <MenuIcon className="h-6 w-6" />
          )}
        </button>

        <div className="flex-1 hidden lg:flex items-center justify-start mr-auto pl-8">
          <div className="w-28 text-white">
            <a href="https://www.vilhemp.hu">
              <Logo className="fill-current lg:py-1" />
            </a>
          </div>
        </div>
        {/* <!-- Logo --> */}
        {/*  <div className="w-36 lg:mx-12 p-4 lg:p-0">
          <NavLink to="/hu/">
            <Logo />
          </NavLink>
        </div> */}
        {/*  <!-- Right Navigation --> */}
        <div className="flex-1 lg:flex justify-end ml-auto hidden text-white items-center">
          {webshopMenu.map((item) => (
            <NavLink
              key={item.name}
              to={generateCategoryUrl(language, item.slug)}
              aria-current={item.current ? "page" : undefined}
              className="uppercase text-sm font-semibold px-4 tracking-widest hover:bg-white hover:text-darkGreen lg:py-4"
            // onMouseEnter={() => handleHover(item.slug)}
            >
              {item.name}
            </NavLink>
          ))}
          <div className="group relative dropdown cursor-pointer">
            <button className=" mx-2 flex uppercase items-center text-sm font-semibold tracking-widest hover:bg-white hover:text-darkGreen lg:py-4 px-1">
              <span className="sr-only">Open user menu</span>
              {/* <UserIcon className="h-6 w-6 mr-1" /> */}
              Ön
            </button>
            <div className="group-hover:block dropdown-menu hidden absolute right-0 h-auto bg-green">
              {auth ? (
                <ul className="pb-2 pt-6 top-0">
                  <li className="py-1 w-full">
                    <NavLink
                      to={`/${language}/profile`}
                      className="flex uppercase text-base font-semibold px-4 tracking-widest hover:bg-white hover:text-darkGreen"
                    >
                      Profilom
                    </NavLink>
                  </li>
                  <li className="py-1 w-full">
                    <NavLink
                      to={`/${language}/orders`}
                      className="flex uppercase text-base font-semibold px-4 tracking-widest hover:bg-white hover:text-darkGreen"
                    >
                      Rendeléseim
                    </NavLink>
                  </li>
                  <li className="py-1 w-full">
                    <button
                      type="button"
                      onClick={handleLogout}
                      className="flex uppercase text-base font-semibold px-4 tracking-widest hover:bg-white hover:text-darkGreen"
                    >
                      Kijelentkezés
                    </button>
                  </li>
                </ul>
              ) : (
                <HeaderLogin />
              )}
            </div>
          </div>

          {/*  <button type="button" className="mx-2 flex uppercase text-base items-center">
            <span className="sr-only">View whishlist</span>
            <HeartIcon className="h-6 w-6 mr-1" />
            Whislist
          </button> */}
          <div className="group dropdown cursor-pointer">
            <NavLink
              to={generateCartUrl(language)}
              className="mx-2 flex uppercase text-sm items-center font-semibold tracking-widest hover:bg-white hover:text-darkGreen lg:py-4 px-1"
            >
              <span className="sr-only">View cart</span>
              {/* <CartIcon className="h-6 w-6 mr-1" /> */} Kosár ({itemsNumber})
            </NavLink>
            <div className="group-hover:block dropdown-menu hidden absolute right-0 h-auto w-80 bg-green p-4">
              <ul className="pb-2 pt-6 top-0">
                <li className="py-1">{<CartHover cart={cart} />}</li>
              </ul>
            </div>
          </div>

          <div className="uppercase text-2xl lg:py-3 tracking-widest hover:bg-white hover:text-darkGreen px-2">
            <a
              href="https://instagram.com/vilhemp"
              target="_blank"
              rel="noopener noreferrer"

            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          <div className="uppercase text-2xl lg:py-3 px-2 tracking-widest hover:bg-white hover:text-darkGreen mx-1 pr-8">
            <a
              href="https://www.facebook.com/vilhemp/"
              target="_blank"
              rel="noopener noreferrer"

            >
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </div>
          {/*  <div className="uppercase text-sm ml-1 my-1 pr-8 font-semibold tracking-widest">
            <a
              role="button"

              onClick={() => handleLanguageChange('hu')}
            >
              HU
            </a>{' '}
            |{' '}
            <a
              role="button"

              onClick={() => handleLanguageChange('en')}
            >
              EN
            </a>
          </div> */}

        </div>
      </div>
      {open /*mobile*/ && (
        <div className="h-screen bg-white flex flex-col items-start">
          {webshopMenu.map((item) => (
            <React.Fragment key={item.name}>
              <button
                type="button"
                className="uppercase text-2xl my-1 px-4 tracking-widest"
                onClick={() => handleMobileSubmenuOpen(item.slug)}
              >
                {item.name}
              </button>

              {activeSubmenu === item.slug ? (
                <div className="pl-12 flex flex-col items-start pb-4">
                  {subMenus[activeSubmenu]}
                </div>
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}

          <NavLink
            to={generateCartUrl(language)}
            className="uppercase text-2xl my-1 px-4 tracking-widest flex items-center justify-center"
          >
            <span className="sr-only">View cart</span>
            <CartIcon className="h-6 w-6 mr-1" /> Kosár ({itemsNumber})
          </NavLink>

          <button
            className="uppercase text-2xl my-1 px-4 tracking-widest flex items-center justify-center"
            onClick={handleLoginOpen}
          >
            <span className="sr-only">Open user menu</span>
            <UserIcon className="h-6 w-6 mr-1" />
            Ön
          </button>
          {loginOpen && !auth && (
            <div className="w-full">
              <HeaderLogin />
            </div>
          )}
          {auth && (
            <>
              <NavLink
                to={`/${language}/profile`}
                className="uppercase text-2xl my-1 px-4 tracking-widest"
              >
                Profilom
              </NavLink>

              <NavLink
                to={`/${language}/orders`}
                className="uppercase text-2xl my-1 px-4 tracking-widest"
              >
                Rendeléseim
              </NavLink>

              <button
                type="button"
                onClick={handleLogout}
                className="uppercase text-2xl my-1 px-4 tracking-widest"
              >
                Kijelentkezés
              </button>
            </>
          )}
          <Prompt
            message={(location, action) => {
              setOpen(false);
              setActiveSubmenu("");
              return true;
            }}
          />
        </div>
      )}

      {activeSubmenu && (
        <div className="md:flex flex-col px-12 pb-5 hidden">
          {activeSubmenu ? <>{subMenus[activeSubmenu]}</> : <></>}
        </div>
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  webshopMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default Header;
