import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import { StarIcon } from '@heroicons/react/outline';

const ReviewRating = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue, touched, errors, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {

    return (
        <div>
            <Rating
                initialRating={field.value}
                onClick={(rating) => setFieldValue('rating', rating)}
                fullSymbol={<StarIconSolid className='h-6 w-6 text-green' />}
                emptySymbol={<StarIcon className={`h-6 w-6 ${errors.rating && touched.rating ? 'text-red-500' : 'text-green'} `} />}
              />
        </div>
    )
}
ReviewRating.propTypes = {};

export default ReviewRating;