import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccordionItem from './../../../shared/components/accordion-item/AccordionItem';
import UserReview from '../user-review/UserReview';

const steps = [
  { key: 'ingredients', label: 'Összetevők' },
  { key: 'shipping_options', label: 'Szállítási opciók' },
  { key: 'review', label: 'Értékelés' }
];

const ProductInfoAccordion = ({ user, auth, product }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const handleAccordionChange = (step) => {
    setCurrentStep(step);
  };

  return (
    <div className="">
      <AccordionItem
        step={0}
        label={steps[0].label}
        active={currentStep === 0}
        onAccordionClick={handleAccordionChange}
        openIcon={true}
      >
        <div className="py-2">
          Kezeletlen fa és rostszál zúzalék, politejsav
        </div>
      </AccordionItem>
      <AccordionItem
        step={1}
        label={steps[1].label}
        active={currentStep === 1}
        onAccordionClick={handleAccordionChange}
        openIcon={true}
      >
        <div className="py-2">
          <ul className="py-2">
            <li className="pb-2">
              Érte megyek: <br />
              1149 Budapest, Mogyoródi út 32. (hétköznap 9.00 és 16.00 óra között, érkezés előtt fél órával hívja a +3630-3366-842-es számot)
            </li>
            <li className="pb-2">
              Kiszállítás Budapest területén (2-3 munkanap): <br />
              bruttó 2.500,- Ft
            </li>
            <li className="pb-2">
              Kiszállítás Budapesten kívül (2-3 munkanap): <br />
              bruttó 3.000,- Ft
            </li>
          </ul>
        </div>
      </AccordionItem>
      {auth &&
        <AccordionItem
          step={2}
          label={steps[2].label}
          active={currentStep === 2}
          onAccordionClick={handleAccordionChange}
          openIcon={true}
        >
          <div className="py-1">
            <UserReview user={user} product={product} />
          </div>
        </AccordionItem>}
    </div>
  );
};

ProductInfoAccordion.propTypes = {
};

export default ProductInfoAccordion;
