import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Address = ({ fields, values }) => {
  const { t } = useTranslation();

  const addressList = fields.map((field) => (
    <tr key={field.name} className="text-sm">
      <td className="pr-8">{t(field.label)}</td>
      <td>{values[field.name]}</td>
    </tr>
  ));

  return (
    <div>
      <table>
        <tbody>{addressList}</tbody>
      </table>
    </div>
  );
};

Address.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
};

export default Address;
