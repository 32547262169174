import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  data: undefined,
  error: false,
};

const shippingZonesSlice = createGenericSlice({
  name: 'shippingZones',
  initialState,
  reducers: {},
});

const { actions, reducer } = shippingZonesSlice;

const shippingZonesState = (state) => state.shippingZones;

export const shippingZonesSelector = createDraftSafeSelector(
  shippingZonesState,
  (state) => ({
    shippingZones: state.data,
    loading: state.loading,
    error: state.error,
  })
);

export const { start, finishError, finishSuccess } = actions;

export default reducer;
