import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";

import { styleObject } from './../select-field/SelectField'

const StateSelectField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  ...props
}) => {
  const [key, name] = field.name.split(".");

  const displayError =
    touched[key] && errors[key] && touched[key][name] && errors[key][name];

  const states = (
    options.find((option) => option.code === values.billing.country)?.states ||
    []
  ).map((s) => ({
    value: s.code,
    label: s.name,
  }));

  const handleSelect = (option) => setFieldValue(field.name, option?.value);

  return (
    <>
      <Select
        defaultValue={states.find(c => c.value === field.value)}
        isClearable={true}
        isSearchable={true}
        isDisabled={!values.billing.country}
        name={field.name}
        options={states}
        onChange={handleSelect}
        styles={styleObject}
        placeholder={props.placeholder}
      />
      {displayError && (
        <p className="text-sm text-red-500">{errors[key][name]}</p>
      )}
    </>
  );
};

StateSelectField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.array.isRequired,
};

export default StateSelectField;
