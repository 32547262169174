/* eslint-disable react/display-name */
import React from 'react';
// import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';

import Loader from './../loader/Loader';
import AccessDenied from './../../../components/access-denied/AccessDenied'

import { userSelector } from './../../../store/slices/userSlice';

const withUserPermission =
  (Component) =>
  ({ ...rest }) => {
    const { auth, loading } = useSelector(userSelector);

    if (loading) {
      return (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      );
    }

    return !loading && auth ? (<Component {...rest} />) : (<AccessDenied />);
  };

withUserPermission.propTypes = {};

export default withUserPermission;
