import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import TextField from '../../../shared/components/text-field/TextField';
import TextAreaField from './../../../shared/components/textarea-field/TextareaField';
import RoundLoader from '../../../shared/components/round-loader/RoundLoader';

const contactFormValidationSchema = (t) =>
  Yup.object({
    email: Yup.string().email(t('e-mail is not correct')).required(t('e-mail is required')),
    name: Yup.string().required(t('name is required')),
    text: Yup.string().required(t('text is required')),
  });

const ContactForm = ({ handleSubmit, loading, error, success }) => {
  const { t } = useTranslation();

  return (
    <div className="py-4 lg:py-14">
      <Formik
        initialValues={{
          email: '',
          name: '',
          text: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={contactFormValidationSchema(t)}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div>
              <Field name="name" placeholder={t('name')} component={TextField} />
            </div>
            <div className="pb-4">
              <Field name="email" placeholder={t('email')} component={TextField} />
              <Field name="text" placeholder={t('message')} height="h-80" component={TextAreaField} />
            </div>

            <button
              type="submit"
              className="border border-green py-4 px-6 flex items-center justify-center w-60 text-green hover:bg-green hover:text-white"
              disabled={loading}
            >
              {loading ? <RoundLoader /> : t('send')}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ContactForm;
