import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReCaptchaV2 from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { start, finishError, finishSuccess, mailSelector } from '../../../store/slices/mailSlice';
import { postMailPath } from '../../../shared/api-path-generators';

import PageContainer from './../../../shared/components/page-container/PageContainer';
import ContactForm from '../contact-form/ContactForm';

const Contact = (props) => {
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const { success, error, loading } = useSelector(mailSelector);
  const { t } = useTranslation();

  const notify = () => {
    toast(t('email_sent'));
  };

  useEffect(() => {
    if (success) {
      toast(t('email_sent'));
    } else if (error) {
      toast(t('email_error'));
    }
  }, [loading, success, error]);

  const handleContactForm = async (values) => {
    const token = await recaptchaRef.current.executeAsync();

    const toPost = {
      ...values,
      name: values.name,
      email: values.email,
      text: values.text,
      'g-recaptcha-response': token,
    };

    dispatch(start(toPost, finishSuccess, finishError, postMailPath(), 'POST'));
  };
  return (
    <PageContainer>
      <div className="mx-auto lg:w-6/12">
        <h1 className="text-2xl">{t('contact us')}</h1>
        {error && <div className="border border-red-500 p-4 text-red-500 mb-4 pt-4">Hiba történt</div>}
        <ContactForm handleSubmit={handleContactForm} loading={loading} error={error} success={success} />
        <ReCaptchaV2 ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY} size="invisible" />
      </div>
    </PageContainer>
  );
};

Contact.propTypes = {};

export default Contact;
