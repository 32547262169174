import { call, put, all } from 'redux-saga/effects';
import { wcHttpRequest } from './api-calls';

export default function* paginationWorkerSaga(action) {
  const {
    payload,
    meta: { successAction, errorAction, url, type },
  } = action;

  const apiCall = wcHttpRequest(url, type);

  try {
    const { headers, data } = yield call(apiCall, payload);

    const total = parseInt(headers['x-wp-total'], 10);
    const totalPages = parseInt(headers['x-wp-totalpages'], 10);

    const stateData = {
        total,
        totalPages,
        data
    }

    yield all([put(successAction(stateData))]);
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}
