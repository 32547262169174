import React from 'react';
import PropTypes from 'prop-types';

const TextareaField = ({
  field,
  form: { touched, errors },
  height,
  ...props
}) => {
  const displayError = touched[field.name] && errors[field.name];
  
  return (
    <div>
      <textarea
        className={`border my-1 p-1 ${displayError ? 'border-red-500 text-red-500 placeholder-red-500' : 'border-green placeholder-green'} w-full ${height}`}
        {...field}
        {...props}
      ></textarea>
      {displayError && <p className="text-sm text-red-500">{errors[field.name]}</p>}
    </div>
  );
};

TextareaField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  height: PropTypes.string,
};

export default TextareaField;
