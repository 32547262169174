import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { login, userSelector } from '../../../../store/slices/userSlice';

import LoginForm from '../../../../shared/components/login-form/LoginForm';

const ExistingUser = ({ handleNext }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(userSelector);

  const handleSubmit = (values) => {
    dispatch(login(values));
  };

  return <LoginForm handleSubmit={handleSubmit} loading={loading} />;
};

ExistingUser.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default ExistingUser;
