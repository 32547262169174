import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  clearCart,
  finishError,
  finishSuccess,
  cartKeySelector,
} from './../../../../store/slices/cartSlice';
import { orderSelector, clear } from './../../../../store/slices/orderSlice';
import { userSelector } from './../../../../store/slices/userSlice';

import { clearCartPath } from './../../../../shared/api-path-generators';

import TransferData from './../transfer-data/TransferData';

const CheckoutSuccess = (props) => {
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { state } = useLocation();
  const { cartKey } = useSelector(cartKeySelector);
  const { order } = useSelector(orderSelector);
  const { auth } = useSelector(userSelector);

  useEffect(() => {
    setOrderId(order.id);
    setPaymentMethod(order.payment_method);
    const clearPath = auth ? clearCartPath() : clearCartPath(cartKey);
    dispatch(clearCart(null, finishSuccess, finishError, clearPath, 'POST'));
    dispatch(clear());
    localStorage.removeItem('cart_key');
    localStorage.removeItem('currentOrder');
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-4xl text-center">Köszönjük a rendelésed!</h1>
      {orderId && (
        <h2 className="text-center text-2xl pt-4">
          Rendelési szám: #{orderId} {state && <>SimplePay tranzakciós azonosító: #
            {state.simpleTransactionId}</>}
        </h2>
      )}
      {orderId && paymentMethod === 'cod' && (<>
        <h2 className="text-2xl pt-4">
          E-mailben értesítünk amint át tudod venni rendelésedet a telephelyünkön. Csak készpénzben tudsz fizetni!
        </h2>
        <div className="pt-4">
          <h3 className="text-lg pb-2">
            Telephelyünk címe:
          </h3>
          <div>1149 Budapest, Mogyoródi út 32. (hétköznap 9.00 és 16.00 óra között, érkezés előtt fél órával hívja a +3630-3366-842-es számot)</div>
        </div>
      </>)}
      {orderId && paymentMethod === 'bacs' && (
        <>
          <h2 className="text-2xl pt-4">
            Kérjük vedd figyelembe, hogy rendelésed feldolgozását az utalás
            beérkezéséig nem tudjuk elkezdeni.
          </h2>
          <div className="pt-4">
            <h3 className="text-lg pb-2">
              Az utaláshoz szükséges információk:
            </h3>
            <p>Rendelési szám: #{orderId}</p>
            <TransferData />
          </div>
        </>
      )}
    </div>
  );
};

CheckoutSuccess.propTypes = {};

export default CheckoutSuccess;
