import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AppContext } from './../../../app/AppContext';

import RoundLoader from '../../../../shared/components/round-loader/RoundLoader';
import GuestUser from './../guest-user/GuestUser';
import ExistingUser from './../existing-user/ExistingUser';

const CustomerInfo = ({ onNext, next, loading, user, guestEmail }) => {
  const { language } = useContext(AppContext);
  const { t } = useTranslation();

  const handleNext = (values) => {
    onNext(values, next);
  };

  const handleLoggedInUserNext = () => {
    onNext(
      {
        id: user.id,
        email: user.email,
      },
      next
    );
  };

  return (
    <div className="w-full pt-8 pb-6">
      {user ? (
        <div>
          <table className="mb-4">
            <tbody>
              <tr>
                <td className="font-bold pr-4">{t('full name')}</td>
                <td>
                  {language === 'hu' ? `${user.last_name} ${user.first_name}` : `${user.first_name} ${user.last_name}`}
                </td>
              </tr>
              <tr>
                <td className="font-bold pr-4">{t('email')}</td>
                <td>{user.email}</td>
              </tr>
            </tbody>
          </table>
          <button
            type="button"
            className="bg-orange py-4 px-6 flex items-center justify-center w-full lg:w-60 text-white hover:bg-darkGreen hover:text-white"
            disabled={loading}
            onClick={handleLoggedInUserNext}
          >
            {loading ? <RoundLoader /> : t('next')}
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="pb-8 md:pb-0 md:pr-8 border-b md:border-b-0 md:border-r border-green">
            <GuestUser handleSubmit={handleNext} loading={loading} guestEmail={guestEmail} />
          </div>
          <div className="pt-8 md:pt-0 md:pl-8">
            <ExistingUser handleNext={handleNext} />
          </div>
        </div>
      )}
    </div>
  );
};

CustomerInfo.propTypes = {
  onNext: PropTypes.func.isRequired,
  next: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object,
  guestEmail: PropTypes.string,
};

export default CustomerInfo;
