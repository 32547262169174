import React from 'react';
import PropTypes from 'prop-types';

import CartListItem from '../cart-list-item/CartListItem';

const CartList = ({ items }) => {
  const cartItems = items.map((item) => <CartListItem key={item.item_key} item={item} />);

  return <>{cartItems}</>;
};

CartList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CartList;
