import { createSlice, createDraftSafeSelector } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const userSlice = createSlice({
  name: "territory",
  initialState,
  reducers: {
    fetchTerritories(state) {
      state.error = false;
      state.loading = true;
    },
    fetchTerritoriesSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchTerritoriesError(state) {
      state.loading = false;
      state.error = true;
    },
  },
});

const { actions, reducer } = userSlice;

const territoryState = (state) => state.territory;

export const territorySelector = createDraftSafeSelector(
  territoryState,
  (state) => ({
    loading: state.loading,
    error: state.error,
    territory: state.data
  })
);

export const {
  fetchTerritories,
  fetchTerritoriesSuccess,
  fetchTerritoriesError,
} = actions;

export default reducer;
