import { call, put, all, select } from 'redux-saga/effects';
import { httpRequest } from './api-calls';

import { addMinutes, format } from 'date-fns';

import { orderSelector } from './../slices/orderSlice'

export default function* simplePayWorkerSaga(action) {
  const {
    payload,
    meta: { successAction, errorAction, url, type },
  } = action;
  const { order } = yield select(orderSelector)
  /*   const salt = randomString(); */

  const simplePayload = {
    orderRef: order.id.toString(),
    currency: 'HUF',
    customerEmail: order.billing.email,
    language: 'HU',
    sdkVersion: 'V1',
    methods: ['CARD'],
    total: order.total,
    timeout: format(addMinutes(new Date(), 5), "yyyy-MM-dd'T'HH:mm:ssxxx"),
    url: 'http://webshop.vilhemp.hu/hu/redirect',
    invoice: {
      name: order.billing.last_name + ' ' + order.billing.first_name,
      company: order.billing.company,
      country: order.billing.country,
      state: order.billing.state,
      city: order.billing.city,
      zip: order.billing.postcode,
      address: order.billing.address_1,
      address2: order.billing.address_2,
      phone: order.billing.phone,
    },
  };

  const apiCall = httpRequest(
    url,
    type,
  );

  try {
    const { data } = yield call(apiCall, simplePayload);

    yield all([put(successAction(data))]);
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}
