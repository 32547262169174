import React from 'react'
import PropTypes from 'prop-types'

const DataTransferText = ({ lang }) => {
    return (
        <div className="pt-4">{lang.toLowerCase() === 'hu' && <div>
            Tudomásul veszem, hogy a(z) Vilhemp Kft. (8624 Balatonszárszó, Zrínyi Miklós utca 68.) adatkezelő által a(z) https://webshop.vilhemp.hu felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: név, email, telefon, számlázási cím.
            Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg: <a target="_blank" rel="noreferrer" href="http://simplepay.hu/vasarlo-aff">http://simplepay.hu/vasarlo-aff</a>
        </div>}
            {lang.toLowerCase() === 'en' && <div>
                I acknowledge the following personal data stored in the user account of Vilhemp Kft. (8624 Balatonszárszó, Zrínyi Miklós utca 68.) in the user database of https://webshop.vilhemp.hu will be handed over to OTP Mobil Ltd. and is trusted as data processor. The data transferred by the data controller are the following: full name, e-mail address, phone number, billing address.
                The nature and purpose of the data processing activity performed by the data processor in the SimplePay Privacy Policy can be found at the following link: <a target="_blank" rel="noreferrer" href="http://simplepay.hu/vasarlo-aff">http://simplepay.hu/vasarlo-aff</a>
            </div>}
        </div>
    )
}

DataTransferText.propTypes = {
    lang: PropTypes.string.isRequired
}

export default DataTransferText