import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    clearCart,
    finishError,
    finishSuccess,
    cartKeySelector,
} from './../../../../store/slices/cartSlice';

import { clearCartPath } from './../../../../shared/api-path-generators';

const CheckoutCanceled = (props) => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { cartKey } = useSelector(cartKeySelector);

    useLayoutEffect(() => {
        dispatch(clearCart(null, finishSuccess, finishError, clearCartPath(cartKey), 'POST'));
        localStorage.removeItem('cart_key');
        localStorage.removeItem('currentOrder');
    }, []);

    return (
        <div className="w-full h-full flex flex-col items-center justify-center px-8">
            <h1 className="text-3xl lg:text-4xl text-center">A #{state.orderId} számú rendelésedhez tartozó fizetés megszakadt. Tranzakció azonosító #{state.simpleTransactionId} </h1>
            <h2 className="text-lg lg:text-2xl pt-4">
                Kérjük vedd fel velünk a kapcsolatot az{' '}
                <a
                    className="hover:underline"
                    href={`mailto:info@vilhemp.hu?subject=${state.orderId + 'rendelés'}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    info@vilhemp.hu
                </a>{' '}
                e-mail címen, és a levél tárgyához írd be a rendelési számod. Köszönjük!
            </h2>
        </div>
    );
};

CheckoutCanceled.propTypes = {}

export default CheckoutCanceled