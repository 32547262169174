import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { orderDetailsUrl } from './../../../shared/frontend-routes';

import ViewIcon from './../../../shared/components/view-icon/ViewIcon';

import { AppContext } from './../../app/AppContext';

export const bgForStatus = {
  pending: 'bg-gray-100',
  processing: 'bg-green bg-opacity-50',
  'on-hold': 'bg-yellow bg-opacity-50',
  completed: 'bg-green bg-opacity-50',
  cancelled: 'bg-gray-100',
  refunded: 'bg-green-100',
  failed: 'bg-red-500',
};

const OrdersListItem = ({ order }) => {
  const { language } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <Link to={orderDetailsUrl(language, order.id)}>
        <div className="border-b border-darkGreen text-sm flex flex-row py-4 items-center">
          <div className="w-2/12 text-left">#{order.number}</div>
          <div className="w-3/12 text-right">{format(new Date(order.date_created), 'yyyy.MM.dd H:mm')}</div>
          <div className="w-3/12 flex justify-end">
            <div className={`p-2 ${bgForStatus[order.status]} uppercase w-max text-center`}>{t(order.status)}</div>
          </div>
          <div className="w-2/12 text-right">
            {order.total} {order.currency}
          </div>
          <div className="w-2/12 flex justify-end">
            <ViewIcon className="w-6 h-6" />
          </div>
        </div>
      </Link>
      <div className="w-full lg:hidden border-b"></div>
    </div>
  );
};

OrdersListItem.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrdersListItem;
