import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  data: null,
  error: false,
};

const sliderSlice = createGenericSlice({
  name: 'slider',
  initialState,
  reducers: {},
});

const { actions, reducer } = sliderSlice;

const sliderState = (state) => state.slider;

export const sliderSelector = createDraftSafeSelector(sliderState, (state) => ({
  items: state.data,
  loading: state.loading,
  error: state.error,
}));

export const { start, finishError, finishSuccess } = actions;

export default reducer;
