import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

const categoriesToFilterOut = ['egyeb'];

export const initialState = {
  loading: false,
  data: [],
  error: false,
};

const shopCategoriesSlice = createGenericSlice({
  name: 'shopCategories',
  initialState,
  reducers: {},
});

const { actions, reducer } = shopCategoriesSlice;

const shopCategoriesState = (state) => state.shopCategories;

const parseCategories = (rawMenu) => {
  const menu = rawMenu
    .filter(
      (item) => !categoriesToFilterOut.includes(item.slug) && !item.parent
    )
    .map((item) => ({
      ...item,
      children: rawMenu.filter((child) => child.parent === item.id),
    }))
    .reverse();

  return menu;
};

export const shopCategoriesSelector = createDraftSafeSelector(
  shopCategoriesState,
  (state) => ({
    categories: parseCategories(state.data),
    loading: state.loading,
    error: state.error,
  })
);

export const rawShopCategoriesSelector = createDraftSafeSelector(
  shopCategoriesState,
  (state) => ({
    categories: state.data,
  })
);

export const { start, finishError, finishSuccess } = actions;

export default reducer;
