/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { territorySelector } from './../../../../store/slices/territorySlice';

import RoundLoader from '../../../../shared/components/round-loader/RoundLoader';
import NestedTextField from '../../../../shared/components/nested-text-field/NestedTextField';
import SelectField from './../../../../shared/components/select-field/SelectField';
import StateSelectField from '../../../../shared/components/state-select-field/StateSelectField';

const fieldMap = (territory) => (t) => (key) => (field) => {
  switch (field.name) {
    case 'country':
      return (
        <div key={field.name} className="flex justify-between items-center">
          <p>{t(field.label)}</p>
          <div className="w-6/12 pb-1">
            <Field
              component={SelectField}
              name={`${key}.${field.name}`}
              placeholder={t('select')}
              options={territory}
            />
          </div>
        </div>
      );
    case 'state':
      return (
        <div key={field.name} className="flex justify-between items-center">
          <p>{t(field.label)}</p>
          <div className="w-6/12 pb-1">
            <Field
              component={StateSelectField}
              name={`${key}.${field.name}`}
              placeholder={t('select')}
              options={territory}
            />
          </div>
        </div>
      );
    default:
      return (
        <div key={field.name} className="flex justify-between items-center">
          <p>{t(field.label)}</p>
          <Field
            component={NestedTextField}
            name={`${key}.${field.name}`}
            placeholder={t(field.label)}
          // className="border my-1 p-2 border-green w-7/12"
          />
        </div>
      );
  }
};

const AddressForm = ({ formFields, values, loading }) => {
  const { t } = useTranslation();
  const { territory, loading: territoryLoading } =
    useSelector(territorySelector);
  const { billing, shipping, same_address } = formFields;

  const billingFields = billing.map(fieldMap(territory)(t)('billing'));
  const shippingFields = shipping.map(fieldMap(territory)(t)('shipping'));

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4 w-full pt-8 pb-2 justify-between">
        <div className="pb-2">
          <h3 className="text-xl">{t('billing address')}</h3>
          {billingFields}
          <label className="inline-flex items-center mt-3">
            <Field
              name={same_address.name}
              type="checkbox"
              className="form-checkbox h-5 w-5 text-gray-600 bg-green"
            />
            <span className="ml-2">{t('Shipping address is the same')}</span>
          </label>
        </div>
        <div className="">
          {!values.same_address && (
            <>
              <h3 className="text-xl">{t('shipping address')}</h3>
              {shippingFields}
            </>
          )}
        </div>
      </div>
      <div className="pb-6">
        <button
          type="submit"
          className="bg-orange py-4 px-6 flex items-center justify-center w-full lg:w-60 text-white hover:bg-darkGreen hover:text-white"
          disabled={loading}
        >
          {loading ? <RoundLoader /> : t('next')}
        </button>
      </div>
    </>
  );
};

AddressForm.propTypes = {
  formFields: PropTypes.object.isRequired,
  values: PropTypes.object,
  loading: PropTypes.bool,
};

export default AddressForm;
