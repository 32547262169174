import React from 'react';
import { useHistory } from 'react-router-dom';

const AccessDenied = (props) => {
  const history = useHistory();

  const handleBack = () => {
    history.push('/');
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-lg md:text-4xl mb-8 text-center">Nincs jogod megtekinteni ezt az oldalt.</h1>
      <button
        type="button"
        onClick={handleBack}
        className="border border-green py-2 px-4 flex items-center justify-center h-10 text-green hover:bg-green hover:text-white"
      >
        Vissza a főoldalra
      </button>
    </div>
  );
};

export default AccessDenied;
