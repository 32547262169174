import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';

import OrderDetailsListItem from './../order-details-list-item/OrderDetailsListItem';

const OrderDetailsList = ({ items }) => {
    const { t } = useTranslation();

    const list = items.map(item => <OrderDetailsListItem key={item.id} item={item} />)

    return (
        <div><>
            <div className="w-full hidden md:flex flex-row pb-4">
                <div className="w-4/12 text-left font-bold">{t('product')}</div>
                <div className="w-2/12 text-left font-bold">{t('color')}</div>
                <div className="w-2/12 text-left font-bold">{t('packaging')}</div>
                <div className="w-2/12 text-left font-bold">{t('quantity')}</div>
                <div className="w-2/12 text-right font-bold">{t('price')}</div>
            </div>
            <div className="w-full flex flex-col">{list}</div>
        </></div>
    )
}

OrderDetailsList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default OrderDetailsList