import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { head } from 'ramda';

import Coupon from "./../checkout/coupon/Coupon";

import { generateCheckoutUrl, generateCategoryUrl } from './../../../shared/frontend-routes';

import { numberFormatter } from './../../../shared/helpers';

const calculateSubtotal = (items) =>
  items.reduce((total, item) => total + (parseInt(item.subtotal, 10) + parseInt(item.subtotal_tax, 10)), 0);

const getShippingCost = (shippingLines) => {
  if (shippingLines.length === 0) {
    return 'Számítás alatt.'
  }
  const shippingLine = head(shippingLines);

  return `${numberFormatter(parseInt(shippingLine.total, 10))} HUF`;

};

const OrderSummary = ({ cart, checkout, order }) => {
  const { t } = useTranslation();

  const subtotal = order
    ? calculateSubtotal(order.line_items)
    : parseInt(cart.totals.subtotal, 10) + parseInt(cart.totals.subtotal_tax, 10);
  const shippingCost = order
    ? getShippingCost(order.shipping_lines)
    : 'Számítás alatt.';
  const total = order
    ? parseInt(order.total, 10) - parseInt(order.shipping_tax, 10)
    : parseInt(cart.totals.subtotal, 10) + parseInt(cart.totals.subtotal_tax, 10);

  return (
    <>
      <h2 className="text-lg text-white pb-2">{t('order summary')}</h2>
      <div className="flex flex-row justify-between pb-1 text-white">
        <div>{t('subtotal')}:</div>
        <div> {numberFormatter(subtotal)} HUF</div>
      </div>
      {order && <div className="flex flex-row justify-between pb-1 text-white">
        <div>Kedvezmény:</div>
        <div>{numberFormatter(order.discount_total)} HUF</div>
      </div>}
      <div className="flex flex-row justify-between pb-2 border-b border-green text-white">
        <div>{t('shipping')}:</div>
        <div>{shippingCost}</div>
      </div>
      <div className="flex flex-row justify-between pt-2 text-white">
        <div>{t('total')}:</div>
        <div>{numberFormatter(total)} HUF</div>
      </div>
      <Coupon />
      {!checkout && (
        <div className="flex flex-col py-6 space-y-4">
          <Link to={generateCheckoutUrl('hu')} className="bg-green p-3 text-center uppercase text-white">
            {t('proceed to checkout')}
          </Link>
          <Link to={generateCategoryUrl('hu')} className="border border-green p-3 text-center uppercase text-green">
            {t('continue shopping')}
          </Link>
        </div>
      )}
      <div className="pt-4 text-white">
        <h2 className="text-lg pb-2">Fontos információ</h2>
        <p>Az árak az ÁFÁ-t tartalmazzák.</p>
        <h3 className="text-lg pb-2">Szállítási opciók</h3>
        <ul className="py-2 text-sm">
          <li className="pb-2">
            Érte megyek: <br />
            1149 Budapest, Mogyoródi út 32. (hétköznap 9.00 és 16.00 óra között, érkezés előtt fél órával hívja a +3630-3366-842-es számot)
          </li>
          <li className="pb-2">
            Kiszállítás Budapest területén (2-3 munkanap): <br />
            bruttó 2.500,- Ft
          </li>
          <li className="pb-2">
            Kiszállítás Budapesten kívül (2-3 munkanap): <br />
            bruttó 3.000,- Ft
          </li>
        </ul>
      </div>
    </>
  );
};

OrderSummary.propTypes = {
  cart: PropTypes.object.isRequired,
  checkout: PropTypes.bool.isRequired,
  order: PropTypes.object,
};

export default OrderSummary;
