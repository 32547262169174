import * as Yup from 'yup';

export const formFields = {
  billing: [
    {
      name: 'last_name',
      label: 'last name',
      requiredMessage: '',
    },
    {
      name: 'first_name',
      label: 'first name',
      requiredMessage: '',
    },
    {
      name: 'address_1',
      label: 'Address line 1',
      requiredMessage: '',
    },
    {
      name: 'address_2',
      label: 'Address line 2',
    },
    {
      name: 'company',
      label: 'Company',
      requiredMessage: '',
    },
    {
      name: 'city',
      label: 'City',
      requiredMessage: '',
    },
    {
      name: 'country',
      label: 'Country',
      requiredMessage: '',
    },
    {
      name: 'state',
      label: 'State',
      requiredMessage: '',
    },
    {
      name: 'postcode',
      label: 'Postcode',
      requiredMessage: '',
    },
    {
      name: 'phone',
      label: 'Phone',
      requiredMessage: '',
    },
  ],
  same_address: {
    name: 'same_address',
    label: 'Use this address as my shipping address',
  },
  shipping: [
    {
      name: 'last_name',
      label: 'last name',
      requiredMessage: '',
    },
    {
      name: 'first_name',
      label: 'first name',
      requiredMessage: '',
    },
    {
      name: 'address_1',
      label: 'Address line 1',
      requiredMessage: '',
    },
    {
      name: 'address_2',
      label: 'Address line 2',
    },
    {
      name: 'company',
      label: 'Company',
      requiredMessage: '',
    },
    {
      name: 'country',
      label: 'Country',
      requiredMessage: '',
    },
    {
      name: 'city',
      label: 'City',
      requiredMessage: '',
    },
    {
      name: 'state',
      label: 'State',
      requiredMessage: '',
    },
    {
      name: 'postcode',
      label: 'Postcode',
      requiredMessage: '',
    },

  ],
  payment_method: {
    name: 'payment_method',
    label: 'Payment method',
    requiredMessage: 'Payment method is required.',
  },
  customer_note: {
    name: 'customer_note',
    label: 'Customer note',
    requiredMessage: ''
  }
};

const checkShippingMethod = (cart, order) => {
  if (order && Array.isArray(order.shipping_lines) && order.shipping_lines.length > 0) {
    return order.shipping_lines[0].method_id;
  }

  return cart && parseInt(cart.totals.subtotal, 10) > 25000 ? 'free_shipping' : 'flat_rate';
};

const addressesSource = (user, order) => {
  if (order && order.billing.address_1) {
    return order
  }
  if (user && user.billing.first_name) {
    return user
  }

  return null
}

export const generateInitialValues = (cart, user, order) => {
  const addressSource = addressesSource(user, order);

  return {
    billing: {
      first_name: addressSource ? addressSource.billing?.first_name : '',
      last_name: addressSource ? addressSource.billing?.last_name : '',
      address_1: addressSource ? addressSource.billing.address_1 : '',
      address_2: addressSource ? addressSource.billing.address_2 : '',
      city: addressSource ? addressSource.billing.city : '',
      state: addressSource ? addressSource.billing.state : '',
      postcode: addressSource ? addressSource.billing.postcode : '',
      country: addressSource ? addressSource.billing.country : '',
      phone: addressSource ? addressSource.billing.phone : '',
    },
    same_address: true,
    shipping: {
      first_name: addressSource ? addressSource.shipping?.first_name : '',
      last_name: addressSource ? addressSource.shipping?.last_name : '',
      address_1: addressSource ? addressSource.shipping.address_1 : '',
      address_2: addressSource ? addressSource.shipping.address_2 : '',
      city: addressSource ? addressSource.shipping.city : '',
      state: addressSource ? addressSource.shipping.state : '',
      postcode: addressSource ? addressSource.shipping.postcode : '',
      country: addressSource ? addressSource.shipping.phone : '',
    },
    shipping_method: checkShippingMethod(cart, order),
    payment_method: order ? order.payment_method : '',
    customer_note: '',
  };
};

export const validationSchemas = (t) => [
  {},
  Yup.object({
    billing: Yup.object({
      first_name: Yup.string().required(t('Please specify your first name.')),
      last_name: Yup.string().required(t('Please specify your last name.')),
      address_1: Yup.string().required(t('Please specify an address.')),
      city: Yup.string().required(t('Please specify a city.')),
      //state: Yup.string().required(t('Please specify a state.')),
      postcode: Yup.string().required(t('Please specify a postcode.')),
      country: Yup.string().required(t('Please specify a country.')),
      phone: Yup.string().required(t('Please specify a phone number.')),
    }),
  }),
  Yup.object({
    shipping_method: Yup.string().required(t('Please specify a shipping method.')),
  }),
  Yup.object({
    payment_method: Yup.string().required(t('Please specify a payment method.')),
  }),
  Yup.object({
    customer_note: Yup.string().min(5, t('Please leave a longer note.'))
  }),
];

export const validateBillingAddress = (address) => {
  return (
    address.first_name &&
    address.last_name &&
    address.address_1 &&
    address.city &&
    address.postcode &&
    address.country &&
    address.phone
  );
};

export const validateShippingAddress = (address) => {
  return (
    address &&
    address.first_name &&
    address.last_name &&
    address.address_1 &&
    address.city &&
    address.postcode &&
    address.country
  );
};
