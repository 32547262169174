import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import PageContainer from '../../../shared/components/page-container/PageContainer';
import Loader from '../../../shared/components/loader/Loader';
import withUserPermission from '../../../shared/components/with-user-permission/withUserPermission';
import OrderDetailsList from './../order-details-list/OrderDetailsList';

import { getOrderById } from '../../../shared/api-path-generators';

import { formFields } from '../../webshop/checkout/checkout/formHelpers';

import { start, finishError, finishSuccess, customerOrdersSelector } from '../../../store/slices/customerOrdersSlice';
import { territorySelector } from '../../../store/slices/territorySlice';

import { bgForStatus } from '../../orders-page/orders-list-item/OrdersListItem';


const paymentMethods = (t) => ({
  cod: t('Cash on delivery'),
  bacs: t('SEPA Transfer'),
  'simplepay-gateway': `${t('Credit or Debit Card')} ${t('with simple')}`
})

const billingFields = formFields.billing;

const OrderDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const routeParams = useParams();
  const { loading, error, customerOrders: order } = useSelector(customerOrdersSelector);
  const { territory, loading: territoryLoading } =
    useSelector(territorySelector);

  useLayoutEffect(() => {
    const id = routeParams.orderId;
    dispatch(start(null, finishSuccess, finishError, getOrderById(id), 'GET'));
  }, [dispatch]);

  const printAddress = (address, t) => {
    const keys = Object.keys(address);

    return keys
      .filter((i) => i !== 'email')
      .map((key, index) => {
        const translationKey = billingFields.find((i) => i.name === key);

        const country = key === 'country' || key === 'state' ? territory.find(i => i.code === address['country']) : null;
        const state = key === 'state' && country ? country.states.find(i => i.code === address[key]) : null;

        const displayData = country && !state ? country.name : state ? state.name : address[key]

        return (
          <div key={key + index} className="flex justify-between">
            <div>{t(translationKey.label)}</div>
            {/* <div>{address[key]}</div> */}
            <div>{displayData}</div>
          </div>
        );
      });
  };

  return (
    <PageContainer>
      {error && <div className="border border-red-500 p-4 text-red-500 mb-4">Hiba történt</div>}
      {loading || territoryLoading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          {order && !Array.isArray(order) && (
            <div className="mx-auto lg:w-8/12">
              <h1 className="text-2xl pb-12">Rendelésed részletei</h1>
              <div className="grid grid-cols-2 space-x-8">
                <div>
                  <div className="flex justify-between pb-4">
                    <div className="flex items-center">Státusz</div>
                    <div className={`p-2 ${bgForStatus[order.status]} uppercase w-max text-center`}>{t(order.status)}</div>
                  </div>
                  <div className="flex justify-between">
                    <div>Rendelési szám</div>
                    <div>#{order.number}</div>
                  </div>
                  <div className="flex justify-between">
                    <div>Fizetesi mód</div>
                    <div>{paymentMethods(t)[order.payment_method]}</div>
                  </div>
                  <div className="flex justify-between">
                    <div>Szállítási mód</div>
                    <div>{order.shipping_lines[0].method_title}</div>
                  </div>
                  <div className="flex justify-between">
                    <div>Létrehozva</div>
                    <div>{format(new Date(order.date_created), 'yyyy.MM.dd H:mm')}</div>
                  </div>
                  <div className="flex justify-between">
                    <div>Teljesítve</div>
                    <div>{order.date_completed ? format(new Date(order.date_completed), 'yyyy.MM.dd H:mm') : '-'}</div>
                  </div>
                  <div className="flex justify-between text-lg font-bold pt-4">
                    <div>Teljes összeg</div>
                    <div>
                      {order.total} {order.currency}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Szállítási díj</div>
                    <div>{order.shipping_total} HUF</div>
                  </div>
                </div>

                <div>
                  <h2 className="text-lg pb-2">Számlázási cím</h2>
                  <div className="pb-4 border-b border-darkGreen">{printAddress(order.billing, t)}</div>
                  <h2 className="text-lg py-2">Szállítási cím</h2>
                  <div>{printAddress(order.shipping, t)}</div>
                </div>
              </div>
              <div className="pt-8">
                <h2 className="text-xl pb-8">Megrendelt termékek</h2>
                <OrderDetailsList items={order.line_items} />
              </div>
            </div>
          )}
        </>
      )}
    </PageContainer>
  );
};

OrderDetails.propTypes = {};

export default withUserPermission(OrderDetails);
