import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';

export const initialState = {
  show: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    show(state) {
      state.show = true;
    },
    hide(state) {
      state.show = false;
    },
  },
});

const { actions, reducer } = modalSlice;

const modalState = (state) => state.modal;

export const modalSelector = createDraftSafeSelector(modalState, (state) => ({
  show: state.show,
}));

export const { show, hide } = actions;

export default reducer;
