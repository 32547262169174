import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import eicLogo from "./eic-logo.jpg";
import hiventuresLogo from "./hiventures.jpg";
import hazaiLogo from "./ht_.svg";
import sparLogo from "./spar.svg";
import profiKomp from "./profikomp_logo.jpg";
import simplePay from "./simplepay_otp_hu_top.svg";

import { AppContext } from "./../../../components/app/AppContext";

const FooterContainer = styled.footer`
  .simpleLogo {
    margin-right: 10px;
    width: 202px;
    background: #ffffff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
  }
  .hiveLogo {
    margin-right: 10px;
    width: 202px;
    height: 77px;
    border-radius: 3px;
    padding: 6px;
    background: #ffffff;
    display: flex;
    align-items: center;
  }

  .eicLogo {
    width: 202px;
    height: 77px;
    border-radius: 3px;
    padding: 6px;
    background: #ffffff;
  }

  .eicLogo img {
    width: 197px;
    height: 67px;
  }
  
  .hazai {
    height: 77px;
    border-radius: 3px;
    background: #ffffff;
    margin-right: 10px;
  }

  .hazai img {
    height: 77px;
  }
  
  .sparLogo {
    height: 77px;
    width: 88px;
    border-radius: 3px;
    background: #ffffff;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sparLogo img {
    height: 67px;
  }

  .profiLink {
    margin-right: 10px;
  }

  .profiKomp {
    height: 77px;
    width: 95px;
    border-radius: 3px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .profiKomp img {
    height: 60px;
  }

  .profiKomp span {
    color: black;
    font-size: 0.4rem;
    }
`;

const Footer = (props) => {
  const { language } = useContext(AppContext);

  return (
    <FooterContainer className="px-8 py-4 flex justify-between items-start xl:items-center flex-col xl:flex-row bg-darkGreen text-white">
      <div className="pb-2 xl:pb-0 flex flex-row">
        <div className="pr-2">© 2021 Vilhemp. All rights reserved.</div>
        <div className="xl:space-x-2 xl:space-y-0 space-y-2 pr-2 flex flex-col xl:flex-row">
          <Link to={`/${language}/aszf`}>ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK</Link>
          <Link to={`/${language}/adatkezelesi-tajekoztato-webshop`}>ADATKEZELÉSI TÁJÉKOZTATÓ</Link>
          {/* <Link to={`/${language}/adatkezelesi-tajekoztato`}>ADATKEZELÉSI TÁJÉKOZTATÓ</Link> */}
        </div></div>
      <div className="flex items-start xl:items-center flex-col xl:flex-row">
        <div className="flex flex-row pt-2 xl:pt-0">
          <a className="simpleLogo" href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank" rel="noreferrer">
            <img src={simplePay} alt="simple pay logo" />
          </a>
          {/* <a
            href="https://profikomp.hu/"
            target="_blank"
            className="profiLink"
            rel="noreferrer"
          >
            <div className="profiKomp">
              <img src={profiKomp} alt="spar logo" />
              <span>Együttműködő partnerünk</span>
            </div>
          </a>
          <div className="sparLogo">
            <img src={sparLogo} alt="spar logo" />
          </div>
          <div className="hazai">
            <a href="https://amagyartermek.hu/">
              <img src={hazaiLogo} alt="hazai termek logo" />
            </a>
          </div>
          <div className="hiveLogo">
            <a
              href="https://www.hiventures.hu/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={hiventuresLogo} alt="hiventures logo" />
            </a>
          </div>
          <div className="eicLogo">
            <a
              href="https://www.climate-kic.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={eicLogo} alt="eic logo" />
            </a>
          </div> */}
        </div>
      </div>
    </FooterContainer>
  );
};

Footer.propTypes = {};

export default Footer;
