import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { clearCart, finishError, finishSuccess, cartKeySelector } from './../../../../store/slices/cartSlice';
import { userSelector } from './../../../../store/slices/userSlice';

import { clearCartPath } from './../../../../shared/api-path-generators';

const CheckoutFailed = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { cartKey } = useSelector(cartKeySelector);
  const { auth } = useSelector(userSelector);

  useLayoutEffect(() => {
    const clearPath = auth ? clearCartPath() : clearCartPath(cartKey);
    dispatch(clearCart(null, finishSuccess, finishError, clearPath, 'POST'));
    localStorage.removeItem('cart_key');
    localStorage.removeItem('currentOrder');
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center px-8">
      {state.simpleTransactionId ? (<>
        <h1 className="text-3xl lg:text-4xl text-center">Sikertelen tranzakció! SimplePay tranzakció azonosító: {state.simpleTransactionId}</h1>
        <h2 className="text-lg lg:text-2xl pt-4">Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét. Amennyiben minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása érdekében kérjük, szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.</h2>
        <h3 className="text-base pt-4">Rendelési szám: #{state.orderId}</h3>
      </>) : (<>
        <h1 className="text-3xl lg:text-4xl text-center">Sajnos a #{state.orderId} számú rendelésed sikertelen volt. </h1>
        <h2 className="text-lg lg:text-2xl pt-4">
          Kérjük vedd fel velünk a kapcsolatot az{' '}
          <a
            className="hover:underline"
            href={`mailto:info@vilhemp.hu?subject=${state.orderId + 'rendelés'}`}
            target="_blank"
            rel="noreferrer"
          >
            info@vilhemp.hu
          </a>{' '}
          e-mail címen, és a levél tárgyához írd be a rendelési számod. Köszönjük!
        </h2>
      </>)}


    </div>
  );
};

CheckoutFailed.propTypes = {};

export default CheckoutFailed;
