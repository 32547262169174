import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  auth: false,
  errorMessage: '',
  user: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state) {
      state.error = false;
      state.auth = false;
      state.loading = true;
    },
    logout(state) {
      state.loading = true;
    },
    validate(state) {
      state.auth = false;
      state.loading = true;
    },
    register: {
      reducer: (state) => {
        state.error = false;
        state.loading = true;
      },
      prepare(payload, successAction, errorAction, url, type, toastMessage) {
        return { payload, meta: { successAction, errorAction, url, type, toastMessage } };
      },
    },
    updateProfile: {
      reducer: (state) => {
        state.error = false;
        state.loading = true;
      },
      prepare(payload, successAction, errorAction, url, type, toastMessage) {
        return { payload, meta: { successAction, errorAction, url, type, toastMessage } };
      },
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.auth = true;
    },
    loginError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
      state.auth = false;
    },
    logoutSuccess(state) {
      state.loading = false;
      state.auth = false;
      state.user = undefined;
      state.error = false;
    },
    logoutError(state, action) {
      state.loading = false;
      state.auth = false;
      state.user = undefined;
      state.error = true;
      state.errorMessage = action.payload;
    },
    registerSuccess(state) {
      (state.loading = false), (state.user = true);
    },
    registerError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    updateProfileSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    updateProfileError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    reset(state) {
      state.user = undefined;
    },
  },
});

const { actions, reducer } = userSlice;

const userState = (state) => state.user;

export const userSelector = createDraftSafeSelector(userState, (state) => ({
  auth: state.auth,
  loading: state.loading,
  error: state.error,
  user: state.user,
}));

export const {
  login,
  validate,
  register,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  logoutError,
  registerSuccess,
  registerError,
  reset,
  updateProfile,
  updateProfileSuccess,
  updateProfileError,
} = actions;

export default reducer;
