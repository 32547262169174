import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import RoundLoader from './../../../shared/components/round-loader/RoundLoader';
import TextField from './../../../shared/components/text-field/TextField';

const guestUserValidationSchema = Yup.object({
  email: Yup.string().email('E-mail is not correct').required('E-mail is required'),
  password: Yup.string().required('Password is required'),
});

const LoginForm = ({ handleSubmit, loading }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Field name="email" component={TextField} placeholder={t('email')} />

          <div className="pb-4">
            <Field name="password" type="password" placeholder={t('password')} component={TextField} />
          </div>
          <div className="space-x-2 flex justify-between">
            <button
              type="submit"
              className="bg-white text-green py-4 px-4 w-full"
            >
              {loading ? <RoundLoader /> : t('login')}
            </button>
            <Link
              to={{
                pathname: `/hu/dialog/register`,
                state: { background: location },
              }}
              className="bg-white text-green py-4 px-4 w-full text-center"
            >
              {t('sign up')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default LoginForm;
