import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Chevron from './../../../shared/components/chevron/Chevron';

const ProductCarouselContainer = styled.div.attrs({})`
  height: 80vh;

  .slick-track {
    height: 100%;
  }
  .slick-list {
    height: 100%;
  }
  .slick-slide div {
    height: 100%;
  }
`;

const ProductImageCarouselItem = ({ image }) => {
  return (
    <div className="h-full px-0.5">
      <img src={image.src} className="h-full w-screen md:w-auto object-cover" alt={image.name} />
    </div>
  );
};

const Arrow = (props) => {
  const { className, style, onClick, side } = props;

  const sides = {
    right: 'right-0',
    left: 'left-0',
  };

  return (
    <div
      className={`hover:bg-black w-12 h-full absolute top-0 hover:bg-opacity-40 ${sides[side]}`}
      style={{ zIndex: '1', ...style }}
      onClick={onClick}
    >
      <div className="h-full w-full flex flex-col items-center justify-center opacity-0 hover:opacity-100">
        <Chevron direction={side} className="h-12 w-12 text-white" />
      </div>
    </div>
  );
};

const ProductImageCarousel = ({ images }) => {
  const settings = {
    className: 'h-full',
    dots: true,
    arrows: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'linear',
    adaptiveHeight: false,
    variableWidth: true,
    centerMode: true,
    focusOnSelect: true,
    nextArrow: <Arrow side="right" />,
    prevArrow: <Arrow side="left" />,
  };

  const list = images.map((img) => <ProductImageCarouselItem key={img.name} image={img} />);

  return (
    <ProductCarouselContainer>
      <Slider {...settings}>{list}</Slider>
    </ProductCarouselContainer>
  );
};

ProductImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProductImageCarousel;
