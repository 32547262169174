import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  data: [],
  error: false,
};

const pageSlice = createGenericSlice({
  name: 'page',
  initialState,
  reducers: {},
});

const { actions, reducer } = pageSlice;

const pageState = (state) => state.page;

export const pageSelector = createDraftSafeSelector(pageState, (state) => ({
  pages: state.data,
  loading: state.loading,
  error: state.error,
}));

export const { start, finishError, finishSuccess } = actions;

export default reducer;
