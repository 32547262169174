import React, { useLayoutEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Loader from './../loader/Loader';

import {
  start,
  finishError,
  finishSuccess,
  orderSelector,
} from './../../../store/slices/orderSlice';

import { userSelector } from '../../../store/slices/userSlice';

import { postOrderPath } from './../../api-path-generators';

import { AppContext } from './../../../components/app/AppContext';

import {
  checkoutSuccesUrl,
  checkoutFailUrl,
  checkoutCancelUrl,
  checkoutTimeoutUrl,
} from './../../frontend-routes';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RedirectHandler = (props) => {
  const { language } = useContext(AppContext);
  const [simpleData, setSimpleData] = useState(null);
  const [redirectState, setRedirectState] = useState(null);
  const dispatch = useDispatch();
  let query = useQuery();

  const { order, loading } = useSelector(orderSelector);
  const { loading: userLoading, user } = useSelector(userSelector);

  const r = query.get('r');
  const s = query.get('s');

  useLayoutEffect(() => {
    const base64Decoded = JSON.parse(atob(r));
    // might want to validate the response.
    setSimpleData(base64Decoded);

    !userLoading &&
      dispatch(
        start(
          null,
          finishSuccess,
          finishError,
          postOrderPath(base64Decoded.o),
          'GET'
        )
      );
  }, [dispatch, userLoading]);

  useLayoutEffect(() => {
    if (order && simpleData) {
      localStorage.removeItem('currentOrder');
      const transactionId = order.transaction_id;

      if (transactionId === simpleData.t.toString()) {
        setRedirectState({
          simpleTransactionId: simpleData.t,
          orderId: order.id,
        });
      }
    }
  }, [order, simpleData]);

  return (
    <>
      {(loading || userLoading) && !order && (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      )}
      {simpleData?.e === 'SUCCESS' && redirectState && (
        <Redirect
          to={{
            pathname: checkoutSuccesUrl('hu'),
            state: { ...redirectState },
          }}
        />
      )}
      {simpleData?.e === 'CANCEL' && redirectState && (
        <Redirect
          to={{
            pathname: checkoutCancelUrl('hu'),
            state: { ...redirectState },
          }}
        />
      )}
      {simpleData?.e === 'FAIL' && redirectState && (
        <Redirect
          to={{
            pathname: checkoutFailUrl('hu'),
            state: { ...redirectState },
          }}
        />
      )}
      {simpleData?.e === 'TIMEOUT' && redirectState && (
        <Redirect
          to={{
            pathname: checkoutTimeoutUrl('hu'),
            state: { ...redirectState },
          }}
        />
      )}
    </>
  );
};

RedirectHandler.propTypes = {};

export default RedirectHandler;
