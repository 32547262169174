import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import RoundLoader from "./../../../shared/components/round-loader/RoundLoader";
import HeartIcon from "./../../../shared/components/heart-icon/HeartIcon";
import TextField from "../../../shared/components/text-field/TextField";

const OUT_OF_STOCK = "outofstock";

const optionMapper = (item) => <option key={item}>{item}</option>

const productValidationSchema = (t) =>
  Yup.object({
    color: Yup.string().required('Kötelező.'),
    packaging: Yup.string().required('Kötelező'),
    quantity: Yup.string().required('Kötelező'),
  });

const UpdatePrice = ({ onPriceUpdate }) => {
  const { values } = useFormikContext();

  React.useEffect(() => {
    onPriceUpdate(values.packaging, values.color)
  }, [values.packaging, values.color, onPriceUpdate])

  return null;
}

const ProductDetailsForm = ({
  onSubmit,
  loading,
  stockStatus,
  packaging,
  colors,
  onPriceUpdate
}) => {
  const { t } = useTranslation();

  const handleSubmit = (values, { resetForm }) => {
    resetForm();
    onSubmit(values);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        color: colors.length > 1 ? "" : colors[0],
        packaging: "",
        quantity: "",
      }}
      validationSchema={productValidationSchema(t)}
    >
      {({ isSubmitting, dirty, errors, values }) => (
        <Form className="h-6/6">
          <UpdatePrice onPriceUpdate={onPriceUpdate} />
          <div className="flex flex-col justify-between">
            {colors.length > 1 && <div className="py-1">
              <Field
                name="color"
                component="select"
                placeholder="Color"
                className="p-1 border border-darkGreen text-darkGreen w-full bg-transparent"
              >
                <option value="">{t('color')}</option>
                {colors.map(optionMapper)}
              </Field>
            </div>}
            <div className="py-1">
              <Field
                name="packaging"
                component="select"
                placeholder="Package"
                className="p-1 border border-darkGreen text-darkGreen w-full bg-transparent"
              >
                <option value="">{t('packaging')}</option>
                {packaging.filter(i => i !== 'egyedi' && i !== 'custom').map(optionMapper)}
              </Field>
            </div>
            <div className="py-1">
              <Field
                name="quantity"
                component={TextField}
                placeholder={t('quantity')}
              />
            </div>

            <div className="py-8 flex items-center space-x-2">
              <button
                type="submit"
                className="bg-orange py-8 md:py-2 px-4 flex items-center justify-center h-10 text-white hover:bg-darkGreen hover:text-white w-full disabled:cursor-not-allowed disabled:bg-white disabled:text-green disabled:opacity-50"
                disabled={loading || stockStatus === OUT_OF_STOCK}
              >
                {loading ? <RoundLoader /> : t("add to cart")}
              </button>
              {/* <button type="button" className="border border-green px-2 text-green hover:text-red-500 h-10">
                <HeartIcon className="h-6 w-6" />
              </button> */}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ProductDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  colors: PropTypes.array.isRequired,
  packaging: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  stockStatus: PropTypes.string,
  onPriceUpdate: PropTypes.func.isRequired,
};

export default ProductDetailsForm;
