import React from 'react';
import PropTypes from 'prop-types';

const RadioField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  height,
  ...props
}) => {
  return <input type="radio" style={{ height: '20px', width: '20px' }} {...field} {...props} />;
};

RadioField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default RadioField;
