const generateCategoryUrl = (lang, category) =>
  `/${lang}/shop/${category ? category : ''}`;

const generateProductUrl = (lang, productSlug) =>
  `/${lang}/shop/product/${productSlug}`;

const generateCartUrl = (lang) => `/${lang}/shop/cart`;

const generateCheckoutUrl = (lang) => `/${lang}/shop/checkout`;

const orderDetailsUrl = (lang, orderId) => `/${lang}/orders/${orderId}`;

const checkoutSuccesUrl = (lang) => `/${lang}/shop/checkout/success`;

const checkoutFailUrl = (lang) => `/${lang}/shop/checkout/failed`;

const checkoutCancelUrl = (lang) => `/${lang}/shop/checkout/canceled`;

const checkoutTimeoutUrl = (lang) => `/${lang}/shop/checkout/timeout`;

export {
  generateCategoryUrl,
  generateProductUrl,
  generateCartUrl,
  generateCheckoutUrl,
  orderDetailsUrl,
  checkoutSuccesUrl,
  checkoutFailUrl,
  checkoutCancelUrl,
  checkoutTimeoutUrl,
};
