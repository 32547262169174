import React from 'react';
import PropTypes from 'prop-types';

const DotsHorizontal = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
      />
    </svg>
  );
};

DotsHorizontal.propTypes = {};

export default DotsHorizontal;
