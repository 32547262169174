import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "../../../shared/components/loader/Loader";

import { generateProductUrl } from "./../../../shared/frontend-routes";

import { ReactComponent as V } from "./../../../shared/components/loader/Vilhemp-V.svg";

/* import { numberFormatter } from "./../../../shared/helpers"; */

const ProductListItemContainer = styled.div`
  .imageContainer {
    height: 60vh;
  }
  /* 	@media (min-width: 640px) {

   } */
  @media (min-width: 768px) {
    .imageContainer {
      height: 56vh;
    }
  }
  @media (min-width: 1024px) {
    .imageContainer {
      height: 28vh;
    }
  }

  @media (min-width: 1280px) {
    .imageContainer {
      height: 50vh;
    }
  }

  @media (min-width: 1536px) {
    .imageContainer {
      height: 60vh;
    }
  }

  .imageLoader {
    height: 60vh;
  }
`;

function ProductListItem({ product }) {
  const { t } = useTranslation();
  const [showBack, setShowBack] = useState(false);
  const [loading, setLoading] = useState(true);
  const mainImage = product.images[0];
  const backImage = product.images.find((img) => img.alt === "back");

  useEffect(() => {
    !mainImage && setLoading(false);
  }, [mainImage])

  const handleHover = () => {
    backImage && setShowBack((prev) => !prev);
  };

  const handleLoading = () => setLoading(false);

  return (
    <ProductListItemContainer>
      <Link to={generateProductUrl("hu", `${product.id}-${product.slug}`)}>
        <div
          className="imageContainer overflow-hidden relative"
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
        >
          {loading && (
            <div className="w-full h-full flex items-center justify-center bg-green bg-opacity-10">
              <Loader />
            </div>
          )}
          {backImage && (
            <img
              className={`object-scale-down w-full backImage ${showBack ? "block" : "hidden"
                }`}
              src={backImage.src}
            />
          )}
          {mainImage && <img
            className={`object-scale-down w-full ${!showBack ? "block" : "hidden"
              }`}
            src={mainImage.src}
            onLoad={handleLoading}
          />}
          {!mainImage && (
            <div className="w-full h-full flex items-center justify-center bg-white">
              <div className="w-48 h-48 p-4">
                <V />
              </div>
            </div>
          )}
        </div>
        <div className="flex items-top justify-between px-4 pb-8 pt-1">
          <div className="uppercase text-sm text-left font-bold">
            {product.name}
          </div>
          {/* <div className="text-right text-sm whitespace-nowrap font-light">
            {product.stock_status === 'outofstock' ? (
              t('outofstock')
            ) : (
              <>{product.regular_price ? numberFormatter(product.regular_price) : numberFormatter(product.price)} HUF</>
            )}
          </div> */}
        </div>
      </Link>
    </ProductListItemContainer>
  );
}

ProductListItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductListItem;
