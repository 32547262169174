import React from 'react';
import PropTypes from 'prop-types';

const TransferData = (props) => {
  return (
    <div className="text-sm">
      <ul>
        <li>Név: Vilhemp kft.</li>
        <li>Bankszámlaszám: 10102103-60458200-01005001</li>
        <li>Bank Neve: Budapest Bank (MKB)</li>
        <li>Közlemény: Megrendelés száma</li>
      </ul>
    </div>
  );
};

TransferData.propTypes = {};

export default TransferData;
