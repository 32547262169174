import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  success: false,
  error: false,
  data: [],
};

const reviewSlice = createGenericSlice({
  name: 'review',
  initialState,
  reducers: {
    success(state) {
      state.success = true;
    },
  },
});

const { actions, reducer } = reviewSlice;

const reviewState = (state) => state.review;

export const reviewSelector = createDraftSafeSelector(reviewState, (state) => ({
  success: state.success,
  error: state.error,
  reviewpayload: state.data,
  loading: state.loading
}));

export const { start, finishError, finishSuccess, success, error, reviewpayload } = actions;

export default reducer;
