import React, { useState, useLayoutEffect, useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { useHistory } from 'react-router-dom';

import PageContainer from './../../../../shared/components/page-container/PageContainer';
import AccordionItem from '../../../../shared/components/accordion-item/AccordionItem';
import CustomerInfo from './../customer-info/CustomerInfo';
import AddressForm from './../address-form/AddressForm';
import PaymentMethod from './../../payment-method/PaymentMethod';
import OrderReview from '../order-review/OrderReview';
import OrderSummary from './../../order-summary/OrderSummary';
import ShippingMethod from './../../shipping-method/ShippingMethod';
import Loader from './../../../../shared/components/loader/Loader';

import { cartSelector } from './../../../../store/slices/cartSlice';
import { userSelector } from './../../../../store/slices/userSlice';
import { shippingMethodsSelector } from './../../../../store/slices/shippingMethodsSlice';

import {
  start,
  finishError,
  finishSuccess,
  orderSelector,
} from './../../../../store/slices/orderSlice';

import {
  start as simplePayStart,
  finishError as simplePayError,
  finishSuccess as simpleyPaySuccess,
  simplePaySelector,
} from './../../../../store/slices/simplepaySlice';

import {
  postOrderPath,
  startSimplePayPayment,
  getOrderById,
  getGuestOrder,
} from './../../../../shared/api-path-generators';

import {
  formFields,
  generateInitialValues,
  validationSchemas,
  validateBillingAddress,
  validateShippingAddress,
} from './formHelpers';

import { AppContext } from './../../../app/AppContext';

const convertToArray = (dataObject) => {
  let array = [];

  for (const [key, value] of Object.entries(dataObject)) {
    array.push({
      key,
      value,
    });
  }

  return array;
};

const Checkout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { language } = useContext(AppContext);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  // const [order, setOrder] = useState(undefined);
  const [orderError, setOrderError] = useState(undefined);
  const { cart, loading: cartLoading } = useSelector(cartSelector);
  const { order: currentOrder, loading } = useSelector(orderSelector);
  const { auth, user, error: userError, loading: userLoading } = useSelector(userSelector);
  const { shippingMethods } = useSelector(shippingMethodsSelector);
  const { loading: simplePayLoading, simplePay } =
    useSelector(simplePaySelector);

  const translatedValidations = validationSchemas(t);

  const steps = [
    { key: 'customer_data', label: t('customer') },
    { key: 'addresses', label: t('billing and shipping addresses') },
    { key: 'shipping_method', label: t('shipping_method') },
    { key: 'payment_method', label: t('payment') },
    { key: 'order_review', label: t('order review') },
  ];

  const handleAccordionChange = (step) => {
    const hasBillingEmail = currentOrder && currentOrder.billing.email;
    const isBillingAddressValid =
      currentOrder && hasBillingEmail && validateBillingAddress(currentOrder.billing);
    const isShippingAddessValid =
      currentOrder && validateShippingAddress(currentOrder.shipping);
    const hasPaymentMethod = currentOrder && currentOrder.payment_method;
    const hasShippingLine = currentOrder && currentOrder.shipping_lines?.length >= 1;

    if (step === 0) {
      setCurrentStep(step);
    }
    if (step === 1) {
      hasBillingEmail && setCurrentStep(step);
    }
    if (step === 2) {
      isBillingAddressValid && isShippingAddessValid && setCurrentStep(step);
    }
    if (step === 3) {
      hasShippingLine && setCurrentStep(step);
    }
    if (step === 4) {
      hasPaymentMethod && setCurrentStep(step);
    }
  };

  useLayoutEffect(() => {
    if (currentOrder && !loading && !cartLoading) {
      if (currentOrder.line_items.length < cart.items.length) {
        const productIdsInOrder = currentOrder.line_items.map(i => i.variation_id)

        const needsTobeAdded = cart.items.filter(item => !productIdsInOrder.includes(item.id))

        const newOrder = {
          id: currentOrder.id,
          line_items: needsTobeAdded.map((item) => ({
            product_id: item.id,
            quantity: item.quantity.value,
            meta_data: convertToArray(item.cart_item_data),
          }))
        }

        dispatch(
          start(
            newOrder,
            finishSuccess,
            finishError,
            postOrderPath(currentOrder.id),
            'PUT'
          )
        );

      }
    }
    if (!currentOrder && !loading && !cartLoading) {
      const orderInProgress = JSON.parse(localStorage.getItem('currentOrder'));
      if (auth) {
        orderInProgress && dispatch(start(null, finishSuccess, finishError, getOrderById(orderInProgress.id), 'GET'));
      } else {
        orderInProgress && dispatch(start({
          id: orderInProgress.id,
          order_key: orderInProgress.order_key,
          email: orderInProgress.billing.email
        }, finishSuccess, finishError, getGuestOrder(), 'POST'))
      }
    }
  }, [dispatch, currentOrder, cart, loading, cartLoading, auth]);

  useLayoutEffect(() => {
    if (currentOrder) {
      if (currentStep === 0) {
        currentOrder.billing.email && setCurrentStep((prev) => prev + 1);
      }
      if (currentStep === 1 && validateBillingAddress(currentOrder.billing) && validateShippingAddress(currentOrder.shipping)) {
        /* validateBillingAddress(currentOrder.billing) &&
          validateShippingAddress(currentOrder.shipping) &&
          setCurrentStep((prev) => prev + 1); */
        if (currentOrder.billing.country != 'HU' || currentOrder.shipping.country != 'HU') {

          const newOrder = {
            id: currentOrder.id,
          };

          newOrder.payment_method === 'bacs';
          newOrder.status = 'on-hold';
          dispatch(
            start(
              newOrder,
              finishSuccess,
              finishError,
              postOrderPath(currentOrder.id),
              'PUT'
            )
          );

          history.push(`/${language}/shop/checkout/foreign-success`);
        } else {
          setCurrentStep((prev) => prev + 1);
        }
      }
      if (currentStep === 2) {
        currentOrder.shipping_lines.length >= 1 &&
          setCurrentStep((prev) => prev + 1);
      }
      if (currentStep === 3) {
        currentOrder.payment_method && setCurrentStep((prev) => prev + 1);
      }
      localStorage.setItem('currentOrder', JSON.stringify(currentOrder));
    }
  }, [currentOrder]);

  useEffect(() => {
    if (simplePay && !simplePay.errorCodes) {
      // localStorage.setItem('orderId', currentOrder.id);
      const newOrder = {
        id: currentOrder.id,
        transaction_id: simplePay.transactionId.toString(),
      };
      dispatch(
        start(
          newOrder,
          finishSuccess,
          finishError,
          postOrderPath(newOrder.id),
          'PUT'
        )
      );
      window.location.href = simplePay.paymentUrl;
    }
  }, [simplePay]);

  useLayoutEffect(() => {
    if (
      currentOrder &&
      (currentOrder.payment_method === 'bacs' || currentOrder.payment_method === 'cod') &&
      currentOrder.status === 'on-hold'
    ) {
      history.push(`/${language}/shop/checkout/success`);
    }
  }, [currentOrder]);

  const createInitialOrder = (customerData, next) => {
    const newOrder = {
      billing: {
        email: customerData.email,
      },
      line_items: cart.items.map((item) => ({
        product_id: item.id,
        quantity: item.quantity.value,
        meta_data: convertToArray(item.cart_item_data),
      })),
    };

    if (customerData.id) {
      newOrder.customer_id = customerData.id;
    }

    //setOrder(newOrder);

    dispatch(
      start(newOrder, finishSuccess, finishError, postOrderPath(), 'POST')
    );
  };

  const handleSubmit = (values) => {
    //const newOrder = clone(currentOrder);
    const newOrder = {
      id: currentOrder.id,
    };

    if (steps[currentStep].key === 'addresses') {
      newOrder.billing = {
        ...currentOrder.billing,
        ...values.billing,
      };
      if (values.same_address) {
        newOrder.shipping = {
          ...values.billing,
        };

        delete newOrder.shipping.phone;
      } else {
        newOrder.shipping = {
          ...values.shipping,
        };
      }
    }

    if (steps[currentStep].key === 'payment_method') {
      newOrder.payment_method = values.payment_method;
    }

    if (steps[currentStep].key === 'shipping_method') {
      const shippingMethodData = shippingMethods.find(
        (method) => method.method_id === values.shipping_method
      );

      if (currentOrder.shipping_lines[0]?.method_id === values.shipping_method) {
        setCurrentStep((prev) => prev + 1);
        return
      }

      let shippingLine = {
        method_id: values.shipping_method,
        method_title: shippingMethodData.method_title,
      };

      if (!isEmpty(currentOrder.shipping_lines)) {
        shippingLine = {
          ...currentOrder.shipping_lines[0],
          method_id: values.shipping_method,
          method_title: shippingMethodData.method_title,
        }
      }

      if (values.shipping_method === 'flat_rate') {
        shippingLine.total = shippingMethodData.settings.cost.value;
      }

      newOrder.shipping_lines = [shippingLine];
    }
    //setOrder(newOrder);

    dispatch(
      start(
        newOrder,
        finishSuccess,
        finishError,
        postOrderPath(newOrder.id),
        'PUT'
      )
    );
  };

  const handlePaymentStart = (customerNote) => {
    const hasBillingEmail = currentOrder && currentOrder.billing.email;
    const isBillingAddressValid =
      currentOrder &&
      hasBillingEmail &&
      validateBillingAddress(currentOrder.billing);
    const isShippingAddessValid =
      currentOrder && validateShippingAddress(currentOrder.shipping);
    const hasPaymentMethod = currentOrder && currentOrder.payment_method;
    const hasShippingLine =
      currentOrder && currentOrder.shipping_lines?.length >= 1;

    const newOrder = {
      id: currentOrder.id,
    };


    if (customerNote) {
      newOrder.customer_note = customerNote;
    }

    if (
      isBillingAddressValid &&
      isShippingAddessValid &&
      hasPaymentMethod &&
      hasShippingLine
    ) {
      if (currentOrder.payment_method === 'bacs' || currentOrder.payment_method === 'cod') {
        newOrder.status = 'on-hold';
      }

      dispatch(
        start(
          newOrder,
          finishSuccess,
          finishError,
          postOrderPath(newOrder.id),
          'PUT'
        )
      );

      if (currentOrder.payment_method === 'simplepay-gateway') {
        dispatch(
          simplePayStart(
            newOrder,
            simpleyPaySuccess,
            simplePayError,
            startSimplePayPayment(),
            'POST'
          )
        );
      }
    } else {
      setOrderError(
        'Hiba történt! Valamit hiányosan töltöttél ki, kérjük ellenőrizd az adatokat!'
      );
    }
  };

  return (
    <PageContainer>
      <h1 className="text-3xl pb-4">{t('checkout')}</h1>
      {userError && (
        <div className="border border-red-500 p-4 text-red-500 mb-4">
          Hiba történt
        </div>
      )}
      {(cartLoading || userLoading) && <Loader />}
      {!cartLoading && !userLoading && cart?.items.length === 0 && <div className="text-center lg:text-left">Jelenleg üres a kosarad.</div>}
      {!cartLoading && !userLoading && cart?.items.length !== 0 && <div className="grid grid-cols-1 lg:grid-cols-3 ">
        <div className="col-span-2 lg:pr-12 pb-4">
          <AccordionItem
            step={0}
            label={steps[0].label}
            active={currentStep === 0}
            onAccordionClick={handleAccordionChange}
            displayIcon={currentOrder && !!currentOrder.billing.email}
          >
            <CustomerInfo
              onNext={createInitialOrder}
              next={1}
              loading={loading}
              user={user}
              guestEmail={currentOrder?.billing?.email}
            />
          </AccordionItem>
          <Formik
            initialValues={generateInitialValues(cart, user, currentOrder)}
            onSubmit={handleSubmit}
            validationSchema={translatedValidations[currentStep]}
            enableReinitialize={true}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <AccordionItem
                  step={1}
                  label={steps[1].label}
                  active={currentStep === 1}
                  onAccordionClick={handleAccordionChange}
                  displayIcon={
                    currentOrder &&
                    validateBillingAddress(currentOrder.billing) &&
                    validateShippingAddress(currentOrder.shipping)
                  }
                >
                  <AddressForm
                    formFields={formFields}
                    values={values}
                    loading={loading}
                  />
                </AccordionItem>
                <AccordionItem
                  step={2}
                  label={steps[2].label}
                  active={currentStep === 2}
                  onAccordionClick={handleAccordionChange}
                  displayIcon={
                    currentOrder && currentOrder.shipping_lines?.length >= 1
                  }
                >
                  <ShippingMethod
                    loading={loading}
                    values={values}
                    freeShipping={cart && cart.totals.subtotal > 25000}
                  />
                </AccordionItem>
                <AccordionItem
                  step={3}
                  label={steps[3].label}
                  active={currentStep === 3}
                  onAccordionClick={handleAccordionChange}
                  displayIcon={currentOrder && currentOrder.payment_method}
                >
                  <PaymentMethod
                    formFields={formFields}
                    values={values}
                    loading={loading}
                  />
                </AccordionItem>
                <AccordionItem
                  step={4}
                  label={steps[4].label}
                  active={currentStep === 4}
                  onAccordionClick={handleAccordionChange}
                >
                  <>
                    {orderError && (
                      <div className="border border-red-500 p-4 text-red-500 mb-4">
                        {orderError}
                      </div>
                    )}
                    <OrderReview
                      values={values}
                      cart={cart}
                      order={currentOrder}
                      onPlaceOrder={handlePaymentStart}
                      loading={loading || simplePayLoading}
                      shippingMethods={shippingMethods}
                      lang={language}
                    />
                  </>
                </AccordionItem>
              </Form>
            )}
          </Formik>
        </div>
        <div className="">
          {!cartLoading && cart && <div className="bg-darkGreen p-6">
            <OrderSummary
              cart={cart}
              checkout={true}
              order={currentOrder}
            />
          </div>}
        </div>
      </div>}
    </PageContainer>
  );
};

Checkout.propTypes = {};

export default Checkout;
