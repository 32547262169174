import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TextField from '../../../shared/components/text-field/TextField';
import RoundLoader from '../../../shared/components/round-loader/RoundLoader';
import SelectField from './../../../shared/components/select-field/SelectField';
import StateSelectField from '../../../shared/components/state-select-field/StateSelectField';



import { territorySelector } from './../../../store/slices/territorySlice';

const profileFormValidationSchema = (t) =>
  Yup.object({
    billing: Yup.object({
      first_name: Yup.string().required(t('Please specify your first name.')),
      last_name: Yup.string().required(t('Please specify your last name.')),
      address_1: Yup.string().required(t('Please specify an address.')),
      city: Yup.string().required(t('Please specify a city.')),
      state: Yup.string().required(t('Please specify a state.')),
      postcode: Yup.string().required(t('Please specify a postcode.')),
      country: Yup.string().required(t('Please specify a country.')),
      phone: Yup.string().required(t('Please specify a phone number.')),
    }),
    shipping: Yup.object({
      first_name: Yup.string().required(t('Please specify your first name.')),
      last_name: Yup.string().required(t('Please specify your last name.')),
      address_1: Yup.string().required(t('Please specify an address.')),
      city: Yup.string().required(t('Please specify a city.')),
      state: Yup.string().required(t('Please specify a state.')),
      postcode: Yup.string().required(t('Please specify a postcode.')),
      country: Yup.string().required(t('Please specify a country.')),
    }),
  });

const generateInitialValues = (user) => ({
  billing: {
    first_name: user ? user.billing.first_name : '',
    last_name: user ? user.billing.last_name : '',
    address_1: user && user.billing ? user.billing.address_1 : '',
    address_2: user && user.billing ? user.billing.address_2 : '',
    city: user && user.billing ? user.billing.city : '',
    state: user && user.billing ? user.billing.state : '',
    postcode: user && user.billing ? user.billing.postcode : '',
    country: user && user.billing ? user.billing.country : '',
    phone: user && user.billing ? user.billing.phone : '',
  },
  shipping: {
    first_name: user ? user.shipping.first_name : '',
    last_name: user ? user.shipping.last_name : '',
    address_1: user && user.shipping ? user.shipping.address_1 : '',
    address_2: user && user.shipping ? user.shipping.address_2 : '',
    city: user && user.shipping ? user.shipping.city : '',
    state: user && user.shipping ? user.shipping.state : '',
    postcode: user && user.shipping ? user.shipping.postcode : '',
    country: user && user.shipping ? user.shipping.country : '',
  },
});

const ProfileForm = ({ user, loading, handleSubmit }) => {
  const { t } = useTranslation();
  const { territory, loading: territoryLoading } =
    useSelector(territorySelector);

  return (
    <div className="w-full">
      <h2 className="text-2xl pb-5">{t('your_profile')}</h2>
      <Formik
        initialValues={generateInitialValues(user)}
        onSubmit={handleSubmit}
        validationSchema={profileFormValidationSchema(t)}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div>
              <h2>
                {user.last_name} {user.first_name}
              </h2>
              <div>{user.email}</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-12 space-y-8 md:space-y-0">
              <div className="">
                <h3 className="text-xl py-3 pb-5 pt-4">{t('billing address')}</h3>
                <div className="pb-4">
                  <p>{t('last name')}</p>
                  <Field name="billing.last_name" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('first name')}</p>
                  <Field name="billing.first_name" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('Address line 1')}</p>
                  <Field name="billing.address_1" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('Address line 2')}</p>
                  <Field name="billing.address_2" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('City')}</p>
                  <Field name="billing.city" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('Country')}</p>
                  <Field name="billing.country" component={SelectField} options={territory} />
                </div>
                <div className="pb-4">
                  <p>{t('State')}</p>
                  <Field name="billing.state" component={StateSelectField} options={territory} />
                </div>
                <div className="pb-4">
                  <p>{t('Postcode')}</p>
                  <Field name="billing.postcode" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('Phone')}</p>
                  <Field name="billing.phone" component={TextField} />
                </div>
              </div>

              <div className="">
                <h3 className="text-xl py-3 pb-5 pt-4">{t('shipping address')}</h3>
                <div className="pb-4">
                  <p>{t('last name')}</p>
                  <Field name="shipping.last_name" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('first name')}</p>
                  <Field name="shipping.first_name" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('Address line 1')}</p>
                  <Field name="shipping.address_1" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('Address line 2')}</p>
                  <Field name="shipping.address_2" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('City')}</p>
                  <Field name="shipping.city" component={TextField} />
                </div>
                <div className="pb-4">
                  <p>{t('Country')}</p>
                  <Field name="shipping.country" component={SelectField} options={territory} />
                </div>
                <div className="pb-4">
                  <p>{t('State')}</p>
                  <Field name="shipping.state" component={StateSelectField} options={territory} />
                </div>
                <div className="pb-4">
                  <p>{t('Postcode')}</p>
                  <Field name="shipping.postcode" component={TextField} />
                </div>
              </div>
            </div>
            <div
              className="pt-4 flex 
              items-left justify-left"
            >
              <button
                type="submit"
                className="bg-orange py-4 px-6 flex items-center justify-center w-full lg:w-60 text-white hover:bg-darkGreen hover:text-white"
              //disabled={loading}
              >
                {loading ? <RoundLoader /> : t('submit')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ProfileForm.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
};

//ProfileForm.displayName = "ProfileForm";

export default ProfileForm;
