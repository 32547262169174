import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

const TextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  height,
  ...props
}) => {
  const displayError = getIn(touched, field.name) && getIn(errors, field.name);

  return (
    <div>
      <input
        type="text"
        className={`border-b bg-transparent my-1 p-1 pl-2 ${
          displayError ? 'border border-red-500 text-red-500 placeholder-red-500' : 'border-darkGreen placeholder-darkGreen'
        } w-full ${height}`}
        {...field}
        {...props}
      />
      {displayError && <p className="text-sm text-red-500">{getIn(errors, field.name)}</p>}
    </div>
  );
};

TextField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  height: PropTypes.string,
};

export default TextField;
