import React from 'react';
import PropTypes from 'prop-types';

import ProductListItem from '../product-list-item/ProductListItem';

function ProductList({ products }) {
  const items = products.map((product) => <ProductListItem key={product.id} product={product} />);
  return <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-4">{items}</div>;
}

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProductList;
