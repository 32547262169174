import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { login, userSelector } from './../../../store/slices/userSlice';

import LoginForm from './../login-form/LoginForm';

const HeaderLogin = (props) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(userSelector);

  const handleSubmit = (values) => {
    dispatch(login(values));
  };

  return (
    <div className="bg-green px-4 py-8 w-full lg:w-80">
      {error && <div className="border border-red-500 p-4 text-red-500 mb-4">Hiba történt</div>}
      <LoginForm handleSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default HeaderLogin;
