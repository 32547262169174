import { isAfter } from 'date-fns';

export const isExisting = (coupon) => {
    return Array.isArray(coupon) && coupon.length === 1;
}

export const isNotExpired = (expireDate) => {
    if (!expireDate) {
        return true
    }

    return isAfter(new Date(expireDate), new Date());
}

export const overallUsageLimit = (usageCount, useageLimit) => {
    if (!useageLimit) {
        return true
    }

    return usageCount < useageLimit;
}

export const userUsageLimit = (usageCount, userUsageLimit) => {
    if (!userUsageLimit) {
        return true
    }

    return usageCount < userUsageLimit;
}

export const userAllowedToUse = (allowedEmails, userEmail) => {
    if (allowedEmails.length === 0) {
        return true
    }

    return allowedEmails.includes(userEmail);
}