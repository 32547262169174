import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from "formik";

import TransferData from './../transfer-data/TransferData';
import Address from './../address/Address';
import CheckoutCartList from './../checkout-cart-list/CheckoutCartList';
import RoundLoader from './../../../../shared/components/round-loader/RoundLoader';
import TextareaField from "./../../../../shared/components/textarea-field/TextareaField";
import DataTransferText from './../DataTransferText/DataTransferText';

import { ReactComponent as SimpleLogo } from './../../../../shared/images/simplepay_bankcard_logos_top_02.svg';
import { formFields } from './../checkout/formHelpers';

const OrderReview = ({
  values,
  cart,
  onPlaceOrder,
  loading,
  shippingMethods,
  order,
  lang
}) => {
  const { t } = useTranslation();
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const acceptTerms = () => {
    setAcceptedTerms((prev) => !prev);
  };

  const shippingMethod = shippingMethods.find(
    (item) => item.method_id === values.shipping_method
  );

  const handlePaymentStart = () => {
    onPlaceOrder(values.customer_note)
  }

  return (
    <div className="pt-4">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <h3 className="text-xl py-3">{t('billing address')}</h3>
          <Address fields={formFields.billing} values={values.billing} />
        </div>
        <div>
          <h3 className="text-xl py-3">{t('shipping address')}</h3>
          <Address
            fields={formFields.shipping}
            values={values.same_address ? values.billing : values.shipping}
          />
        </div>
      </div>
      <h3 className="text-xl py-3">{t('payment')}</h3>
      <div>
        {values.payment_method === 'bacs' && (
          <>
            <div className="pb-2">Fizetés közvetlenül a bankszámlánkra.</div>
            <TransferData />
          </>
        )}
        {values.payment_method === 'simplepay-gateway' && (
          <div className="w-min">
            <div className="text-sm pb-2">
              {t('Credit or Debit Card')} {t('with simple')}.
            </div>
            <div className="bg-white p-4 border">
              <a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank" rel="noreferrer">
                <SimpleLogo className="h-16" />
              </a>
            </div>
          </div>
        )}
        {/* {values.payment_method === 'paypal' && <div>paypal</div>} */}
      </div>
      <h3 className="text-xl py-3">{t('shipping_method')}</h3>
      <div className="pb-3">
        {t(shippingMethod.method_title)}:{' '}
        <span className="text-sm">
          {t(`${shippingMethod.method_id}_description`)}
        </span>
      </div>
      <h3 className="text-xl py-3">{t('items')}</h3>
      <CheckoutCartList items={cart.items} />
      <div className="py-6">
        <h3 className="text-xl py-3">{t("Customer note")}</h3>
        <Field component={TextareaField} name="customer_note" placeholder={t("Customer note")} />
      </div>
      <div className="py-6">
        <label className="flex space-x-2 items-center justify-center">
          <div style={{ height: '20px', width: '20px' }}>
            <input
              style={{ height: '20px', width: '20px' }}
              type="checkbox"
              name="terms and conditions"
              value={acceptedTerms}
              onChange={acceptTerms}
              className="mr-2"
            />
          </div>
          <div>
            Tudomásul veszem és elfogadom az általános szerződési feltételeket
            (ASZF), és az adatkezelési tájékoztatót, valamint a rendeléssel
            járó fizetési kötelezettséget.
            {order.payment_method === 'simplepay-gateway' && <DataTransferText lang={lang} />}
          </div>
        </label>
      </div>
      <div className="flex justify-center flex-col items-center pb-8">
        <button
          type="button"
          className="bg-orange py-4 px-6 flex items-center justify-center w-full lg:w-60 text-white hover:bg-darkGreen hover:text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white disabled:text-green"
          onClick={handlePaymentStart}
          disabled={!acceptedTerms}
        >
          {loading ? <RoundLoader /> : t('finalizie order')}
        </button>
      </div>
    </div>
  );
};

OrderReview.propTypes = {
  values: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  onPlaceOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  shippingMethods: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.object,
  lang: PropTypes.string,
};

export default OrderReview;
