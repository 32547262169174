import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Select from "react-select";

export const styleObject = {
  container: (provided, state) => ({
    ...provided,
    background: 'transparent',
    width: '100%',
  }),
  input: (provided, state) => ({
    ...provided,
    background: 'transparent'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    borderRadius: '0',
    borderColor: '#213e3c',
    width: '100%',
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    color: '#213e3c'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#213e3c'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#213e3c'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: '#213e3c'
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: '#213e3c'
  }),
}

const SelectField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  ...props
}) => {
  const [key, name] = field.name.split(".");

  const displayError =
    touched[key] && errors[key] && touched[key][name] && errors[key][name];

  const countries = options.map((option) => ({
    value: option.code,
    label: option.name,
  }));

  const handleSelect = option => setFieldValue(field.name, option?.value);

  return (
    <>
      <Select
        defaultValue={countries.find(c => c.value === field.value)}
        isClearable={true}
        isSearchable={true}
        name={field.name}
        options={countries}
        onChange={handleSelect}
        styles={styleObject}
        placeholder={props.placeholder}
      />
      {displayError && (
        <p className="text-sm text-red-500">{errors[key][name]}</p>
      )}
    </>
  );
};

SelectField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.array.isRequired,
};

export default SelectField;
