import { call, put, all, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { httpRequest, authedHttpRequest } from './api-calls';

import {
  getCart,
  finishError as cartError,
  finishSuccess as cartSuccess,
  cartKeySelector,
} from './../../store/slices/cartSlice';

import { userSelector } from './../slices/userSlice';

import { getCartPath } from './../../shared/api-path-generators';

export default function* deleteWorkerSaga(action) {
  const {
    payload,
    meta: { errorAction, url, type, toastMessage },
  } = action;

  const { auth } = yield select(userSelector);

  const token = localStorage.getItem('vilhemp-token');

  const apiCall = auth ? authedHttpRequest(url, type) : httpRequest(url, type);

  const postData = {
    token,
    data: payload
  }

  try {
    const toSend = auth ? postData : payload;
    const { data } = yield call(apiCall, toSend);

    const { cartKey } = yield select(cartKeySelector);

    try {
      toastMessage && toast(toastMessage);

      const authedCartPath = auth ? getCartPath() : getCartPath(cartKey);
      yield put(getCart(null, cartSuccess, cartError, authedCartPath, 'GET'));
    } catch (error) {
      yield all([put(errorAction(error))]);
    }
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}
