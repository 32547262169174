import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../../shared/components/page-container/PageContainer';
import withUserPermission from '../../../shared/components/with-user-permission/withUserPermission';
import ProfileForm from '../profile-form/ProfileForm';

import { userSelector, updateProfile, updateProfileSuccess, updateProfileError } from '../../../store/slices/userSlice';

import { updateCustomer } from './../../../shared/api-path-generators';

const Profile = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, loading } = useSelector(userSelector);

  const onSubmit = (values) => {
    values.billing.email = user.email;
    dispatch(
      updateProfile(
        values,
        updateProfileSuccess,
        updateProfileError,
        updateCustomer(),
        'PUT',
        'Sikeresen frissítetted a profilod.'
      )
    );
  };

  return (
    <PageContainer>
      <div className="mx-auto flex lg:w-6/12 items-center justify-center pb-8">
        <ProfileForm user={user} handleSubmit={onSubmit} />
      </div>
    </PageContainer>
  );
};

Profile.propTypes = {};

export default withUserPermission(Profile);
