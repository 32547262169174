import React, { useLayoutEffect, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import RouterContainer from "./../../shared/route-container/RouteContainer";
import Header from "../../shared/components/header/Header";
import Loader from "./../../shared/components/loader/Loader";
import Footer from "./../../shared/components/footer/Footer";
import CustomCookieConsent from "../cookie-consent/CustomCookieConsent";

import {
  start,
  finishSuccess,
  finishError,
  pageSelector,
} from "./../../store/slices/pageSlice";

import {
  start as shopCategoriesStart,
  finishSuccess as shopCategoriesSuccess,
  finishError as shopCategoriesError,
  shopCategoriesSelector,
} from "./../../store/slices/shopCategoriesSlice";

import {
  getCart,
  finishError as cartError,
  finishSuccess as cartSuccess,
} from "./../../store/slices/cartSlice";

import {
  start as shippingZonesStart,
  finishSuccess as shippingZonesSuccess,
  finishError as shippingZonesError,
  shippingZonesSelector,
} from "./../../store/slices/shippingZonesSlice";

import { validate, logout, userSelector } from "./../../store/slices/userSlice";

import { fetchTerritories } from "./../../store/slices/territorySlice";

import {
  getWebshopCategories,
  getCartPath,
  getShippingZones,
  getPages,
} from "./../../shared/api-path-generators";

import { AppContext } from "./AppContext";

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const { categories, loading, error } = useSelector(shopCategoriesSelector);
  const { loading: shippingZonesLoading } = useSelector(shippingZonesSelector);
  const { loading: pagesLoading, error: pagesError } =
    useSelector(pageSelector);
  const { auth } = useSelector(userSelector);
  const [pixel, setPixel] = useState(undefined);
  const [cookies] = useCookies(["CookieConsent"]);
  const [cookieConsent, setCookieConsent] = useState(() => {
    if (cookies.CookieConsent) {
      ReactGA.initialize([
        {
          trackingId: "G-HR6SB1PN8P",
        },
      ]);
      return true;
    }
    return false;
  });
  let location = useLocation();

  useEffect(() => {
    cookieConsent &&
      ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location, cookieConsent]);

  useLayoutEffect(() => {
    const ReactPixel = require("react-facebook-pixel");
    ReactPixel.default.init("745390299724381");

    const cookieConsent = !!cookies.CookieConsent;

    if (!cookieConsent) {
      ReactPixel.default.revokeConsent();
    } else {
      ReactPixel.default.pageView();
    }
    setPixel(ReactPixel.default);
  }, []);

  useLayoutEffect(() => {
    const token = localStorage.getItem("vilhemp-token");
    token && dispatch(validate({ token }));

    dispatch(start(null, finishSuccess, finishError, getPages(), "GET"));
    dispatch(
      shopCategoriesStart(
        null,
        shopCategoriesSuccess,
        shopCategoriesError,
        getWebshopCategories(),
        "GET"
      )
    );
    dispatch(
      shippingZonesStart(
        {},
        shippingZonesSuccess,
        shippingZonesError,
        getShippingZones("hu"),
        "GET"
      )
    );
    dispatch(fetchTerritories());
  }, [dispatch]);

  useLayoutEffect(() => {
    const cartKey = localStorage.getItem("cart_key");
    if (auth) {
      const cartUrl = cartKey ? getCartPath(cartKey) : getCartPath();
      dispatch(getCart(null, cartSuccess, cartError, cartUrl, "GET"));
      cartKey && localStorage.removeItem("cart_key");
    } else {
      cartKey &&
        dispatch(
          getCart(null, cartSuccess, cartError, getCartPath(cartKey), "GET")
        );
    }
  }, [dispatch, auth]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleGrantConsent = () => {
    pixel.grantConsent();
    setCookieConsent(true);
  };

  const handlePageViewLog = () => {
    pixel.pageView();
  };

  return (
    <AppContext.Provider
      value={{
        language,
      }}
    >
      <div className="h-full flex flex-col">
        {loading ||
        shippingZonesLoading ||
        pagesLoading ||
        categories.length === 0 ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>
            <Header webshopMenu={categories} onLogout={handleLogout} />
            <RouterContainer onPageChange={handlePageViewLog} />
            <Footer />
            <Toaster />
            <CustomCookieConsent onGrantConsent={handleGrantConsent} />
          </>
        )}
      </div>
    </AppContext.Provider>
  );
}

export default App;
