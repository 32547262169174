import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { numberFormatter } from './../../../shared/helpers';

const OrderDetailsListItem = ({ item }) => {
    const { t } = useTranslation();

    const color = item.meta_data.find(i => i.display_key === 'color');
    const packaging = item.meta_data.find(i => i.display_key === 'packaging');

    return (
        <div className="flex flex-col md:flex-row w-full pb-2 border-b border-darkGreen mb-2">
            <div className="w-full md:w-4/12 flex items-center">{item.parent_name}</div>
            <div className="w-full flex md:hidden py-2">
                <div className="text-xs w-6/12">
                    <ul>
                        <li className="flex justify-between">
                            <div>Mennyiség:</div> <div>{item.quantity} db</div>
                        </li>
                    </ul>
                </div>
                <div className="pl-4 w-6/12">
                    {color.value}
                </div>
            </div>
            <div className="w-2/12 text-right items-center hidden md:flex">
                {color.value}
            </div>
            <div className="w-2/12 text-right items-center hidden md:flex">
                {packaging.value} / csomag
            </div>
            <div className="w-2/12 text-right items-center hidden md:flex">
                {item.quantity} db
            </div>
            <div className="w-full md:w-2/12 text-right flex items-center justify-end">
                {numberFormatter(parseInt(item.total, 10) + parseInt(item.total_tax, 10))} HUF
            </div>
        </div>
    );
}

OrderDetailsListItem.propTypes = {
    item: PropTypes.object.isRequired
}

export default OrderDetailsListItem