import { call, put, all } from 'redux-saga/effects';
import toast from 'react-hot-toast';

import { httpRequest, authedHttpRequest } from './api-calls';
import {
  authenticateUser,
  getCustomer,
  valideteToken,
  cocartLogoutPath,
  revokeToken,
  clearCartPath,
} from './../../shared/api-path-generators';

import { loginSuccess, loginError, logoutSuccess, logoutError } from './../slices/userSlice';

import {
  resetCart,
} from './../../store/slices/cartSlice';

export function* loginWorkerSaga(action) {
  const { payload } = action;

  const apiCall = httpRequest(authenticateUser(payload.email, payload.password), 'POST');

  try {
    const { data } = yield call(apiCall, {});

    const token = data.data.jwt;

    localStorage.setItem('vilhemp-token', token);

    try {
      const getCusomerCall = authedHttpRequest(getCustomer(), 'GET');
      const { data: userData } = yield call(getCusomerCall, {
        data: undefined,
        token,
      });
      toast('Sikeres bejelentkezés');
      yield all([put(loginSuccess(userData))]);
    } catch (error) {
      yield all([put(loginError(error))]);
    }
  } catch (error) {
    toast('Sikertelen bejelentkezés');
    yield all([put(loginError(error))]);
  }
}

export function* validateWorkerSaga(action) {
  const {
    payload: { token },
  } = action;

  const apiCall = authedHttpRequest(valideteToken(), 'GET');

  try {
    const { data } = yield call(apiCall, { token });

    if (data.success) {
      try {
        const getCusomerCall = authedHttpRequest(getCustomer(), 'GET');
        const { data: userData } = yield call(getCusomerCall, {
          data: undefined,
          token,
        });
        toast('Sikeres bejelentkezés');
        yield all([put(loginSuccess(userData))]);
      } catch (error) {
        yield all([put(loginError(error))]);
      }
    }
  } catch (error) {
    yield all([put(loginError(error))]);
  }
}

export function* logoutWorkerSaga(action) {
  const revokeCall = authedHttpRequest(revokeToken(), 'POST');
  const cocartLogout = authedHttpRequest(cocartLogoutPath(), 'POST');
  const clearCart = authedHttpRequest(clearCartPath(), 'POST');

  const token = localStorage.getItem('vilhemp-token');

  try {
    const [clearResponse, logoutResponse] = yield all([
      call(clearCart, { data: undefined, token }),
      call(cocartLogout, { data: undefined, token }),
    ]);

    if (logoutResponse.status === 200) {
      try {
        yield call(revokeCall, { data: undefined, token });

        localStorage.removeItem('vilhemp-token');
        toast('Sikeresen kijelentkeztél.');
        yield all([put(logoutSuccess()), put(resetCart())]);
      } catch (error) {
        toast('Hiba történt.');
        yield all([put(logoutError(error))]);
      }
    }
  } catch (error) {
    toast('Hiba történt.');
    yield all([put(logoutError(error))]);
  }
}
