import React from 'react';
import PropTypes from 'prop-types';

const PaymentMethodField = ({
  field,
  form: { touched, errors },
  icon,
  text,
  header,
  ...props
}) => (
  <label className="mb-4 border border-darkGreen p-5 w-full flex items-center hover:border-green cursor-pointer">
    <div className="">
      <input {...field} {...props} style={{ width: '20px', height: '20px' }} />
    </div>
    <div className="flex pl-5 w-full">
      <div className="flex flex-col justify-center">
        <div className="text-headline font-bold">{header}</div>
        {text && <div className="text-subheadline">{text}</div>}
      </div>
      {icon && <div className="flex items-center ml-4">{icon()}</div>}
    </div>
  </label>
);

PaymentMethodField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  icon: PropTypes.func,
  text: PropTypes.string,
  header: PropTypes.string,
};

export default PaymentMethodField;
