import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../components/app/AppContext';

const CartHover = ({ cart }) => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  let cartItems = <div className="content-center">{t('empty_bag')}</div>;

  if (cart?.item_count > 0) {
    const currency = cart.currency.currency_code;

    cartItems = cart.items.map((item) => (
      <React.Fragment key={item.item_key}>
        <div className="grid grid-cols-3 gap-2 place-items-center h-auto w-72">
          <div className="pb-4">
            <img src={item.featured_image} />
          </div>
          <div className="pb-4 col-span-2">
            <div>{item.title}</div>
            <div className="pt-4">
              {Math.round(item.totals.total + item.totals.tax)} {currency}
            </div>
          </div>

        </div>
      </React.Fragment>
    ));
  }

  return (
    <div className="pr-4 bg-green">
      <div className="">
        <div>{cartItems}</div>
        {(cart?.item_count > 0) && <Link to={`/${language}/shop/cart`}>
          <button
            type="button"
            className="border border-green text-green bg-white py-2 px-2 w-full"
          >
            {t('goto_bag')}
          </button>
        </Link>}
      </div>
    </div>
  );
};

CartHover.propTypes = {};

export default CartHover;
