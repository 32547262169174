import { takeLatest, takeEvery } from 'redux-saga/effects';

export const latestWatcher = (actionType, workerSaga) =>
  function* watcher() {
    yield takeLatest(actionType, workerSaga);
  };

export const everyWatcher = (actionType, workerSaga) =>
  function* watcher() {
    yield takeEvery(actionType, workerSaga);
  };
