import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReCaptchaV2 from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { start, finishError, finishSuccess, reviewSelector } from '../../../store/slices/reviewSlice';
import { postReviewPath } from '../../../shared/api-path-generators';

import ReviewForm from '../review-form/ReviewForm';

const UserReviewForm = ({ user, product }) => {

    const dispatch = useDispatch();
    const recaptchaRef = React.createRef();
    const { success, error, loading } = useSelector(reviewSelector);
    const { t } = useTranslation();

    useEffect(() => {
        if (success) {
            toast(t('review_sent'));
        } else if (error) {
            toast(t('review_error'));
        }
    }, [loading, success, error]);

    const handleReviewForm = async (values) => {

        const token = await recaptchaRef.current.executeAsync();
        const toPost = {
            product_id: product.id,
            review: values.text,
            rating: values.rating,
            'g-recaptcha-response': token,
        };

        dispatch(start(toPost, finishSuccess, finishError, postReviewPath(), 'POST'));
    };

    return (

        <div>
            <ReviewForm user={user} handleSubmit={handleReviewForm} loading={loading} error={error} />
            <ReCaptchaV2 ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY} size="invisible" />
        </div>

    );
};

UserReviewForm.propTypes = {};

export default UserReviewForm;