import { createDraftSafeSelector } from '@reduxjs/toolkit';

import createGenericSlice from './genericSlice';

export const initialState = {
  loading: false,
  data: [],
  error: false,
};

const customerOrdersSlice = createGenericSlice({
  name: 'customerOrders',
  initialState,
  reducers: {},
});

const { actions, reducer } = customerOrdersSlice;

const customerOrdersState = (state) => state.customerOrders;

export const customerOrdersSelector = createDraftSafeSelector(customerOrdersState, (state) => ({
  customerOrders: state.data,
  loading: state.loading,
  error: state.error,
}));

export const { start, finishError, finishSuccess } = actions;

export default reducer;
