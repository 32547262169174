import React from 'react';
import PropTypes from 'prop-types';

import CheckIcon from './../check-icon/CheckIcon';
import Chevron from './../chevron/Chevron';

const AccordionItem = ({ active, step, label, onAccordionClick, displayIcon, openIcon, children }) => {
  const handleHeaderClick = () => {
    onAccordionClick(step);
  };

  return (
    <div>
      <div
        onClick={handleHeaderClick}
        className="py-4 border-b border-darkGreen flex justify-between items-center cursor-pointer"
      >
        <h2 className="text-xl">{label}</h2>
        {displayIcon && <CheckIcon className="h-6 w-6" />}
        {openIcon && <Chevron className="h-6 w-6" direction={active ? 'up' : 'down'} />}
      </div>
      {active && <>{children}</>}
    </div>
  );
};

AccordionItem.propTypes = {
  active: PropTypes.bool,
  step: PropTypes.number,
  label: PropTypes.string,
  onAccordionClick: PropTypes.func,
  children: PropTypes.node,
  displayIcon: PropTypes.bool,
  openIcon: PropTypes.bool,
};

export default AccordionItem;
