import axios from 'axios';

const WC_KEY = process.env.REACT_APP_WC_KEY;
const WC_SECRET = process.env.REACT_APP_WC_SECRET;

const instance = axios.create({});

const responseBody = (response) => response;

const httpRequest = (url, method) => (params) =>
  instance
    .request({
      method,
      url,
      data: params,
      withCredentials: true,
    })
    .then(responseBody);

const simplepayHttpRequest = (url, method, sign) => (params) =>
  instance
    .request({
      method,
      url,
      withCredentials: false,
      data: params,
      headers: {
        Signature: sign,
        'Content-Type': 'text/plain',
      },
    })
    .then(responseBody);

const wcHttpRequest = (url, method) => (params) =>
  instance
    .request({
      method,
      url,
      data: params,
      auth: {
        username: WC_KEY,
        password: WC_SECRET,
      },
    })
    .then(responseBody);

const authedHttpRequest = (url, method) => (params) =>
  instance
    .request({
      method,
      url,
      data: params.data,
      headers: { 'X-Vilhemp-Auth': `Bearer ${params.token}` },
      withCredentials: true,
    })
    .then(responseBody);

export { httpRequest, wcHttpRequest, authedHttpRequest, simplepayHttpRequest };
