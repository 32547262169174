import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { isEmpty } from 'ramda';

import RadioField from './../../../shared/components/radio-field/RadioField';
import Loader from "./../../../shared/components/loader/Loader";

import {
  shippingMethodsSelector,
  start,
  finishError,
  finishSuccess,
} from './../../../store/slices/shippingMethodsSlice';
import { shippingZonesSelector } from './../../../store/slices/shippingZonesSlice';

import { getShippingMetods } from './../../../shared/api-path-generators';

import RoundLoader from './../../../shared/components/round-loader/RoundLoader';
import { numberFormatter } from '../../../shared/helpers';

const HUNGARY_CODE = 'HU';
const BUDAPEST_CODE = 'BU';

const BUDAPEST = 'Budapest';

const zones = {
  HU: 'Magyarország',
  BU: 'Budapest',
};

const toFilter = (shippingAddress) => {
  if (shippingAddress.city.toLowerCase() === BUDAPEST.toLocaleLowerCase()) {
    return zones[BUDAPEST_CODE];
  }
  if (shippingAddress.country === HUNGARY_CODE) {
    return zones[HUNGARY_CODE];
  }

  return 'Other';
};

const ShippingMethod = ({ loading, freeShipping, values }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    shippingMethods,
    loading: shippingMethodsLoading,
    error,
  } = useSelector(shippingMethodsSelector);
  const { shippingZones } = useSelector(shippingZonesSelector);

  useEffect(() => {
    const address = values.same_address ? values.billing : values.shipping;
    const value = toFilter(address);
    const zone = shippingZones.find((item) => item.name === value);

    dispatch(
      start({}, finishSuccess, finishError, getShippingMetods(zone.id), 'GET')
    );
  }, [shippingZones, values.shipping, values.billing, values.same_address]);

  const fields = shippingMethods.map((method) => (
    <label
      key={method.method_id}
      className="border border-darkGreen hover:border-green cursor-pointer p-8 w-full lg:w-64 flex items-center"
    >
      <div className="flex items-center">
        <div style={{ height: '20px', width: '20px' }}>
          <Field
            component={RadioField}
            name="shipping_method"
            value={method.method_id}
            disabled={
              (method.method_id !== 'free_shipping' && freeShipping) ||
              (method.method_id === 'free_shipping' && !freeShipping)
            }
            checked={values.shipping_method === method.method_id}
          />
        </div>
        <div className="pl-4 flex flex-col">
          <p className="text-lg pb-2">{t(method.method_title)}</p>
          {method.settings.cost.value && <p className="text-sm pb-2">Ár: {numberFormatter(method.settings.cost.value)} HUF </p>}
          <div className="text-xs">{t(`${method.method_id}_description`)}</div>
        </div>
      </div>
    </label>
  ));

  return (
    <div className="w-full pt-8">
      {shippingMethodsLoading && <div className="flex items-center justify-center h-full">
        <Loader />
      </div>}
      {isEmpty(shippingMethods) ? (
        <div></div>
      ) : (
        <>
          <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
            {fields}
          </div>
          <div className="pt-4 pb-6">
            <button
              type="submit"
              className="bg-orange py-4 px-6 flex items-center justify-center w-full lg:w-60 text-white hover:bg-darkGreen hover:text-white"
              disabled={loading}
            >
              {loading ? <RoundLoader /> : t('next')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

ShippingMethod.propTypes = {
  loading: PropTypes.bool,
  freeShipping: PropTypes.bool,
  values: PropTypes.object,
};

export default ShippingMethod;
