import { call, put, all } from "redux-saga/effects";
import { wcHttpRequest } from "./api-calls";

import {
  getCountries,
  getContinentData,
} from "../../shared/api-path-generators";

import {
  fetchTerritoriesSuccess,
  fetchTerritoriesError,
} from "./../slices/territorySlice";

const EU_CODE = "eu";

export default function* territoryWorkerSaga(action) {
  const countriesApiCall = wcHttpRequest(getCountries(), "GET");
  const continentApiCall = wcHttpRequest(getContinentData(EU_CODE), "GET");

  try {
    const [countriesResponse, continentResponse] = yield all([
      call(countriesApiCall),
      call(continentApiCall),
    ]);

    const continentCountriesCode = continentResponse.data.countries.map(item => item.code);

    const continentCountriesData = countriesResponse.data.filter(c => continentCountriesCode.includes(c.code));

    yield all([put(fetchTerritoriesSuccess(continentCountriesData))]);
  } catch (error) {
    yield all([put(fetchTerritoriesError(error))]);
  }
}
