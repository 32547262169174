import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  trimColor,
} from './../../cart-list-item/CartListItem';

import { numberFormatter } from '../../../../shared/helpers';

const CheckoutCartListItem = ({ item }) => {
  const { t } = useTranslation();

  const color = item.meta.variation.color;
  const matchedColorName = trimColor(color);
  const matchedPackaging = trimColor(item.meta.variation.packaging)

  return (
    <div className="flex flex-col md:flex-row w-full pb-2 border-b border-darkGreen mb-2">
      <div className="w-full md:w-4/12 flex items-center">{item.title}</div>
      <div className="w-full flex md:hidden py-2">
        <div className="text-xs w-6/12">
          <ul>
            {/* {itemData} */}
            <li className="flex justify-between">
              <div>Mennyiség:</div> <div>{item.quantity.value} db</div>
            </li>
          </ul>
        </div>
        <div className="pl-4 w-6/12">
          {matchedPackaging[1]} / csomag
        </div>
        <div className="pl-4 w-6/12">
          {matchedColorName[1]}
        </div>
      </div>
      <div className="w-2/12 text-right items-center hidden md:flex">
        {matchedPackaging[1]} / csomag
      </div>
      <div className="w-2/12 text-right items-center hidden md:flex">
        {matchedColorName[1]}
      </div>
      <div className="w-2/12 text-right items-center hidden md:flex">
        {item.quantity.value}
      </div>
      <div className="w-full md:w-2/12 text-right flex items-center justify-end">
        {numberFormatter(parseInt(item.totals.total, 10) + parseInt(item.totals.tax, 10))} HUF
      </div>
    </div>
  );
};

CheckoutCartListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CheckoutCartListItem;
