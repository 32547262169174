import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RoundLoader from './../round-loader/RoundLoader';

import TextField from './../text-field/TextField';

const registerUserValidationSchema = (t) =>
  Yup.object({
    first_name: Yup.string().required('Kötelező.'),
    last_name: Yup.string().required('Kötelező'),
    email: Yup.string().email(t('E-mail cím formátuma nem megfelelő')).required(t('Kötelező')),
    password: Yup.string().required(t('Kötelező')),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], t('passwords must match'))
      .required(t('Kötelező')),
  });

const RegisterForm = ({ handleSubmit, loading }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={registerUserValidationSchema(t)}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div>
            <Field name="last_name" component={TextField} placeholder={t('last name')} />
          </div>
          <div>
            <Field name="first_name" component={TextField} placeholder={t('first name')} />
          </div>
          <div>
            <Field name="email" component={TextField} placeholder={t('email')} />
          </div>
          <div>
            <Field name="password" type="password" component={TextField} placeholder={t('password')} />
          </div>
          <div className="pb-2">
            <Field
              name="confirm_password"
              type="password"
              component={TextField}
              placeholder={t('confirm your password')}
            />
          </div>
          <div className="pb-3">Sikeres regisztráció után kérjük jelentkezz be.</div>
          <button
            type="submit"
            className="border border-green py-4 px-6 flex items-center justify-center w-full lg:w-60 text-green hover:bg-green hover:text-white"
            disabled={loading}
          >
            {loading ? <RoundLoader /> : t('register')}
          </button>
        </Form>
      )}
    </Formik>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default RegisterForm;
