import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    clearCart,
    finishError,
    finishSuccess,
    cartKeySelector,
} from './../../../../store/slices/cartSlice';
import { userSelector } from './../../../../store/slices/userSlice';

import { orderSelector, clear } from './../../../../store/slices/orderSlice';

import { clearCartPath } from './../../../../shared/api-path-generators';

const CheckoutForeign = (props) => {
    const dispatch = useDispatch();
    const [orderId, setOrderId] = useState(null);
    const { state } = useLocation();
    const { cartKey } = useSelector(cartKeySelector);
    const { order } = useSelector(orderSelector);
    const { auth } = useSelector(userSelector);

    useEffect(() => {
        setOrderId(order.id);

        const clearPath = auth ? clearCartPath() : clearCartPath(cartKey);
        dispatch(clearCart(null, finishSuccess, finishError, clearPath, 'POST'));
        dispatch(clear());
        localStorage.removeItem('cart_key');
        localStorage.removeItem('currentOrder');
    }, []);


    console.log(state)
    return (
        <div className="w-full h-full flex flex-col items-center justify-center">
            <h1 className="text-4xl text-center">Thank you for your order!</h1>
            {orderId && (
                <h2 className="text-center text-2xl pt-4">
                    Your order number: #{orderId}, because you ordered outside Hungary, our colleague will get in touch with you about the final price (tax may vary depending on your location) and the shipping.
                </h2>
            )}
        </div>
    );
};

CheckoutForeign.propTypes = {}

export default CheckoutForeign