import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import { StarIcon } from '@heroicons/react/outline';

import { start, finishError, finishSuccess, reviewListSelector } from '../../../store/slices/reviewListSlice';
import { getReviewPath } from '../../../shared/api-path-generators';
import Pagination from '../review-pagination/Pagination';
import Loader from './../../../shared/components/loader/Loader';

const PAGE_SIZE = 5;

const UserReviewList = props => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { success, error, loading, reviewListPayload, total, totalPages } = useSelector(reviewListSelector);

  useEffect(() => {
    dispatch(start(null, finishSuccess, finishError, getReviewPath(currentPage, PAGE_SIZE), 'GET'));
  }, [dispatch, currentPage]);

  return (

    <div className="pt-16 px-12  py-4 justify-between items-center ">
      {loading && <div className="flex items-center justify-center">
        <Loader />
      </div>}
      {!loading && reviewListPayload.length > 0 && <>
        {reviewListPayload.map((item) => {
          return (
            <div key={item.id}>
              <div className="italic text-lg pb-2 pt-2 border-t border-green" key={item.first_name}>
                {`${item.reviewer} írta a(z) ${item.name} termékhez ekkor: ${item.date_created.substring(0, 4)}.${item.date_created.substring(5, 7)}.${item.date_created.substring(8, 10)}`}
              </div>
              <Rating
                initialRating={item.rating}
                readonly
                fullSymbol={<StarIconSolid className='h-6 w-6 text-green' />}
                emptySymbol={<StarIcon className='h-6 w-6 text-green' />}
                className="pb-2"
              />
              <div className="pb-4 px-1" key={item.review}>{item.review.replace(/<\/?[^>]+(>|$)/g, "")}</div>
            </div>
          );
        })}
        <Pagination
          className='justify-between items-center pt-4'
          currentPage={currentPage}
          totalCount={total}
          pageSize={PAGE_SIZE}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        /></>}
    </div>
  );
};

UserReviewList.propTypes = {};

export default UserReviewList;