import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { rawShopCategoriesSelector } from './../../../store/slices/shopCategoriesSlice';

import { start, finishSuccess, finishError, productsSelector } from './../../../store/slices/productsSlice';

import { getProductsByCategory, getAllProducts } from './../../../shared/api-path-generators';

import ProductList from '../product-list/ProductList';
import ListBreadcrumb from '../../../shared/components/list-breadcrumb/ListBreadcrumb';
import Loader from './../../../shared/components/loader/Loader';

function Webshop(props) {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const [productCategory, setProductCategory] = useState(null);
  const { categories } = useSelector(rawShopCategoriesSelector);
  const { products, loading, error } = useSelector(productsSelector);

  useLayoutEffect(() => {
    const categorySlug = routeParams.subcategory ? routeParams.subcategory : routeParams.category;
    const category = categories.find((cat) => cat.slug === categorySlug);
    if (category) {
      setProductCategory(category ? category : '');
    } else {
      dispatch(start({}, finishSuccess, finishError, getAllProducts('hu'), 'GET'));
    }
  }, [routeParams.subcategory, routeParams.category]);

  useLayoutEffect(() => {
    productCategory &&
      dispatch(start({}, finishSuccess, finishError, getProductsByCategory(productCategory.id), 'GET'));
  }, [productCategory]);

  return (
    <div className="h-full pt-16 pb-8 lg:pb-0 lg:pt-24">
      {error && <div>error</div>}
      {loading || !products ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <div className="px-8">
          <div className="pb-16 pt-12 md:pt-14 text-4xl uppercase flex justify-center">{productCategory?.name || 'Termékek'}</div>
          {/* <ListBreadcrumb selectedCategory={productCategory} categories={categories} /> */}
          <ProductList products={products} />
        </div>
      )}
    </div>
  );
}

Webshop.propTypes = {};

export default Webshop;
