import React from 'react';
import { usePagination, DOTS } from './usePagination';

import Chevron from './../../../shared/components/chevron/Chevron';
import DotsHorizontal from '../../../shared/components/dots-horizontal/DotsHorizontal';

const paginationItem = center => `p-4 h-6 flex ${center ? 'items-center' : 'items-start'} justify-center text-base hover:bg-green hover:bg-opacity-50`;
const chevronItemDisabled = disabled => `${disabled ?
  'p-4 h-6 flex items-center justify-center text-base h-6' : 'p-4 h-6 flex items-center justify-center text-base h-6 hover:bg-green hover:bg-opacity-50'}`

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    totalPages,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onLast = () => {
    onPageChange(totalPages);
  }

  const onFirst = () => {
    onPageChange(1);
  }

  return (
    <>
      {paginationRange?.length > 0 && <ul
        className={`flex list-none pagination-container ${className}`}
      >
        <li

          className={`${chevronItemDisabled(parseInt(currentPage, 10) === 1)}`}
          onClick={() => { currentPage !== 1 && onFirst() }}
        ><Chevron direction="left" className="h-6" />
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <li key={index} className={`${paginationItem()}`}><DotsHorizontal className="w-6 h-6" /></li>;
          }

          return (
            <li key={index}
              className={`${paginationItem(true)} ${pageNumber === parseInt(currentPage, 10) ? 'border border-green' : ''}`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}

        <li
          className={`${chevronItemDisabled(parseInt(currentPage, 10) === parseInt(totalPages, 10))}`}

          onClick={() => { currentPage !== totalPages && onLast() }}
        ><Chevron direction="right" className="h-6" />
        </li>
      </ul>}
    </>

  );
};

export default Pagination;
