import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  coupon: undefined,
  error: false,
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    getCoupon: {
      reducer: (state) => {
        state.error = false;
        state.loading = true;
      },
      prepare(payload, successAction, errorAction, url, type) {
        return { payload, meta: { successAction, errorAction, url, type } };
      },
    },
    finishSuccess(state, action) {
      state.coupon = action.payload;
      state.loading = false;
    },
    finishError(state, action) {
      state.coupon = undefined;
      state.loading = false;
    },
    reset(state){
      state.coupon = undefined;
    }
  },
});
const { actions, reducer } = couponSlice;
const couponState = (state) => state.coupons;

export const couponSelector = createDraftSafeSelector(couponState, (state) => ({
  coupon: state.coupon,
  loading: state.loading,
  error: state.error,
}));

export const { getCoupon, finishError, finishSuccess, reset } = actions;

export default reducer;
