import { call, put, all } from 'redux-saga/effects';
import { wcHttpRequest } from './api-calls';

export default function* wcWorkerSaga(action) {
  const {
    payload,
    meta: { successAction, errorAction, url, type },
  } = action;

  const apiCall = wcHttpRequest(url, type);

  try {
    const { data } = yield call(apiCall, payload);
    yield all([put(successAction(data))]);
  } catch (error) {
    yield all([put(errorAction(error))]);
  }
}
