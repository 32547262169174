import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ProductDetailsFrom from "./../product-details-form/ProductDetailsForm";
import ProductInfoAccordion from "./../product-info-accordion/ProductInfoAccordion";
import Loader from "./../../../shared/components/loader/Loader";
import ProductImageCarousel from "../product-image-carousel/ProductImageCarousel";
import ListBreadcrumb from "./../../../shared/components/list-breadcrumb/ListBreadcrumb";
import UserReviewList from "./../user-review-list/UserReviewList";

import {
  getProductById,
  getProductVariations,
  postToCart,
} from "./../../../shared/api-path-generators";

import {
  start,
  productSuccess,
  variationsSuccess,
  finishError,
  reset,
  productSelector,
} from "./../../../store/slices/productSlice";

import {
  addToCart,
  finishError as cartError,
  addToCartSuccess,
  cartKeySelector,
  cartSelector,
} from "./../../../store/slices/cartSlice";

import { userSelector } from "./../../../store/slices/userSlice";
import { rawShopCategoriesSelector } from "./../../../store/slices/shopCategoriesSlice";

import { numberFormatter, createMarkup } from "./../../../shared/helpers";

const searchVariation = (variations, color, packaging) => variations.find(
  (v) => v.color.includes(color) && parseInt(v.packaging, 10) === parseInt(packaging, 10)
);

const Product = (props) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { product, variations, loading, error } = useSelector(productSelector);
  const { cartKey } = useSelector(cartKeySelector);
  const { loading: cartLoading, error: cartErrorState } =
    useSelector(cartSelector);
  const { user, auth, loading: userLoading } = useSelector(userSelector);
  const { categories } = useSelector(rawShopCategoriesSelector);
  const [price, setPrice] = useState(null);

  const colors = product?.attributes.find((attr) => attr.name === "color");
  const packaging = product?.attributes.find(
    (attr) => attr.name === "packaging"
  );

  useLayoutEffect(() => {
    const id = routeParams.productSlug.split("-")[0];
    dispatch(start({}, productSuccess, finishError, getProductById(id), "GET"));
    return () => {
      dispatch(reset());
    };
  }, []);

  useLayoutEffect(() => {
    const id = routeParams.productSlug.split("-")[0];
    product &&
      dispatch(
        start(
          {},
          variationsSuccess,
          finishError,
          getProductVariations(id),
          "GET"
        )
      );
  }, [product]);

  const handlePrice = (product, priceValue) => {
    return priceValue ? numberFormatter(Math.round(priceValue * 1.27)) + ' HUF' : numberFormatter(Math.round(product.price * 1.27)) + 'HUF-tól';
  }

  const handlePriceUpdate = (packaging, color) => {
    if (packaging && color) {
      const variation = searchVariation(variations, color, packaging);
      setPrice(variation.price)
    }
  }

  const handleAddToCart = (productData) => {
    const variation = searchVariation(variations, productData.color, productData.packaging)

    const postCartKey = auth ? null : cartKey;
    setPrice(null)
    dispatch(
      addToCart(
        {
          id: `${variation.id}`,
          quantity: `${productData.quantity}`,
          variation: {
            attribute_color: productData.color,
            attribute_packaging: productData.packaging,
          },
        },
        addToCartSuccess,
        cartError,
        postToCart(postCartKey),
        "POST",
        "Hozzáadtuk a kosaradhoz."
      )
    );
  };

  return (
    <div className="h-full pt-16 pb-8 lg:pb-0 lg:pt-24">
      {error && (
        <div className="border border-red-500 p-4 text-red-500 mb-4">
          Hiba történt
        </div>
      )}
      {loading &&
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      }
      {product && (
        <div className="grid grid-cols-1 xl:grid-cols-3">
          <div className="pb-8 xl:col-span-2">
            {/*  <ListBreadcrumb selectedCategory={productCategory} categories={categories} product={product} /> */}
            <ProductImageCarousel images={product.images} />
            <UserReviewList />
          </div>
          <div className="px-6 pb-8 lg:pl-16">
            {cartErrorState && (
              <div className="border border-red-500 p-4 text-red-500 mb-4">
                Hiba történt
              </div>
            )}
            <div className="pb-14">
              <h1 className="text-3xl	pb-2 font-light">{product.name}</h1>
              <h2>Bruttó ár {handlePrice(product, price)}</h2>
            </div>
            {product.description && (
              <div
                className="pb-14"
                dangerouslySetInnerHTML={createMarkup(product.description)}
              ></div>
            )}
            <ProductDetailsFrom
              colors={colors.options}
              packaging={packaging.options}
              onSubmit={handleAddToCart}
              loading={cartLoading}
              stockStatus={product.stock_status}
              onPriceUpdate={handlePriceUpdate}
            />
            <ProductInfoAccordion auth={auth} user={user} product={product} />
          </div>
        </div>
      )}
    </div>
  );
};

Product.propTypes = {};

export default Product;
